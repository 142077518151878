{
  "navBar": {
    "home": "Accueil",
    "submitTournament": "Publiez un tournoi",
    "my-tournaments": "Mes tournois",
    "all-tournaments": "Tous les Tournois",
    "gameToValidate": "Jeux à valider",
    "contact": "Contact",
    "games": "Tournois",
    "users": "Utilisateurs",
    "login": "Connexion",
    "account": {
      "name": "Compte",
      "setting": "Paramètre",
      "logout": "Déconnection"
    }
  },
  "sideBar": {
    "title": "Filtre",
    "keyword": "Mot clés",
    "region": {
      "name": "Région",
      "choose": "Choisir"
    },
    "date": {
      "startDate": "Date de début",
      "endDate": "Date de fin"
    },
    "search": "Rechercher"
  },
  "record": {
    "dragNDrop": "Glisser-déposer, ou cliquez pour sélectionner une image",
    "limit": "Limite",
    "picture-too-heavy": "Image trop lourde"
  },
  "common": {
    "yes": "Oui",
    "no": "Non",
    "to": "a",
    "of": {
      "default": "of",
      "d": "d'"
    },
    "and": "et",
    "new": "nouveau",
    "or": "ou",
    "more": "Plus",
    "choose": "Choisir",
    "hasBeen": "à été",
    "open": "Ouvrir",
    "close": "Fermer",
    "copy": "Copié",
    "identifier": "Identifiant",
    "confirm": "Valider",
    "address": "Adresse",
    "phoneNumber": "Numéro de téléphone",
    "mail": "Email",
    "details": "Détails",
    "country": "Pays",
    "username": "Nom d'utilisateur",
    "password": {
      "name": "Mot de passe",
      "rule": {
        "1SpecialCharacter": "Un caractère spécial",
        "1UppercaseCharacter": "Une lettre en majuscule",
        "1LowercaseCharacter": "Une lettre en minuscule",
        "moreThan8Characters": "Plus de 8 lettres"
      }
    },
    "firstname": "Prénom",
    "lastname": "Nom de famille",
    "male": "Garçon",
    "female": "Fille",
    "other": "Autre"
  },
  "region": {
    "ANTWERP": "Anvers",
    "EAST_FLANDERS": "Flandre Orientale",
    "FLEMISH_BRABANT": "Brabant Flamand",
    "LIMBURG": "Limbourg",
    "WEST_FLANDERS": "Flandre Occidentale",
    "HAINAUT": "Hainaut",
    "LIEGE": "Liège",
    "LUXEMBOURG": "Luxembourg",
    "NAMUR": "Namur",
    "WALLOON_BRABANT": "Brabant Wallon",
    "BRUSSELS_CAPITAL_REGION": "Région de Bruxelles-Capitale"
  },
  "button": {
    "enable": "Valider",
    "activate": "Activer",
    "activated": "Activé",
    "disable": "Désactiver",
    "disabled": "Désactivé",
    "reOpen": "Reouvrir",
    "waitingForValidation": "En attente de validation",
    "add": "Ajouter",
    "update": "Modifier",
    "cancel": "Annuler",
    "delete": "Supprimer",
    "back": "Retour",
    "save": "Enregistrer",
    "send": "Envoyer"
  },
  "toast": {
    "error": {
      "not-found": "Pas trouvé",
      "user-not-found": "L'utilisateur n'existe pas",
      "session-not-found": "La session n'existe pas",
      "game-not-found": "Le tournoi n'existe pas",
      "auth-not-found": "L'accréditation n'existe pas",
      "media-not-found": "La publication n'existe pas",

      "mail-not-valid": "Le mail n'est pas valide",
      "validation-fail": "Échec de la validation",
      "access-denied": "Accès refusé",
      "user-not-yet-verified": "Utilisateur pas encore vérifié",
      "process-issue": "Erreur sur le processus",
      "root-undeactivable": "L'utilisateur root invalide ne peut pas être désactivé",
      "account-verified": "Compte déjà vérifié",
      "user-disabled": "L'utilisateur est désactivé",
      "retry-another-code": "Réessayez avec un autre code ou régénérez-en un",
      "user-duplication": "Utilisateur déjà existant",
      "auth-denied": "Autorisation refusée",
      "invalid-file-size": "Taille de fichier non valide",
      "username-duplication": "Nom d'utilisateur déjà pris",

      "server-error": "Une erreur de serveur inattendue s'est produit"
    },
    "success": {
      "user-created": "Utilisateur créé avec succès",
      "connected": "Connecté",
      "new-code-generated": "Nouveau code généré sur votre boîte mail",
      "password-update": "Mot de passe mis à jour"
    },
    "info": {
      "event-waiting-validate": "Événement en attente validation"
    }
  },
  "level": {
    "name": "Niveau",
    "leisure": "Loisir",
    "dep": "Départementale",
    "reg": "Régional",
    "nat": "National",
    "elite": "Élite",
    "pro": "Pro",
    "legend": "Légende"
  },
  "success": {
    "title": "Félicitation",
    "default-message": "Votre action a été validé"
  },
  "error": {
    "message": "Désolé, une erreur inattendue s'est produite."
  },
  "settings": {
    "name": "Paramètres",
    "theme": {
      "auto": "Auto",
      "light": "Éclairé",
      "dark": "Sombre"
    },
    "language": {
      "name": "Langue",
      "french": "Français",
      "english": "Anglais"
    }
  },
  "home": {
    "noGameFound": "Aucun jeu trouvé",
    "recently-added": "Nouveau",
    "weekOf": "Semaine du",
    "players": "Joueurs",
    "gender": {
      "onlyMale": "Uniquement garçon",
      "onlyFemale": "Uniqument fille",
      "unknown": "Choix multiples",
      "mixed": "Mixte",
      "male": "garçon",
      "female": "fille",
      "min": "minimum"
    },
    "deleteFilter": "Supprimer les filtres",
    "sureToDeleteGame": "Êtes-vous sûr de vouloir supprimer ce tournoi"
  },
  "login": {
    "title": "Veuillez vous connecter",
    "rememberMe": "Se souvenir",
    "signIn": "Se connecter",
    "continue-as-guest": "Continuez en tant qu'invité",
    "createAccount": "créer un compte",
    "passwordForgotten": "mot de passe oublié",
    "validAccount": "valider votre compte"
  },
  "registry": {
    "title": "Veuillez vous inscrire",
    "mailAddress": "Adresse e-mail",
    "passwordConfirmation": "Confirmer le mot de passe",
    "bornDate": "Date de naissance",
    "bornDateInput": "Date de naissance - ex: 1970-02-30",
    "registry": "Enregistrement",
    "rule": {
      "bornDate": {
        "userShouldBeOlderThan18": "L'utilisateur doit être âgé de plus de 18 ans"
      }
    }
  },
  "identifier": {
    "title": "Ajouter votre identifiant",
    "next": "Suivant"
  },
  "password": {
    "title": "Réinitialisation",
    "newPassword": "Nouveau mot de passe",
    "code": "code",
    "reset": "Réinitialiser",
    "newCode": "Nouveau code"
  },
  "code": {
    "title": "Verification",
    "code": "Code",
    "send": "Envoyer",
    "newCode": "Nouveau code"
  },
  "gameCardEdition": {
    "title": "Mes tournois",
    "newEvent": "Nouveau"
  },
  "games-all": {
    "title": "Tous les tournois"
  },
  "gameEdition": {
    "title": "Organisez votre événement",
    "name": "Nom de l'événement",
    "address": "Adresse",
    "region": "Région",
    "price": "Prix",
    "phoneNumber": "Numero de téléphone",
    "mail": "Mail",
    "file": {
      "name": "Choisir une image",
      "noFile": "Pas d'image choisit"
    },
    "addImage": "Ajouter une image",
    "deleteImage": "Supprimer l'image",
    "nbOfTeam": "Nombre d'équipe",
    "nbOfGround": "Nombre de terrain",
    "nbOfPlayerByTeam": "Nombre de joueur par équipe",
    "minOfMale": "Nombre min de garçon",
    "minOfFemale": "Nombre min de fille",
    "detail": "Détail",
    "default-message": "Message par défaut",
    "email-sending-code": "Mail d'envoie du code",
    "code-send-by-mail": "Code envoyé par mail",
    "congrats":"Votre tournoi à bien été crée, il est en attente de validation"
  },
  "managers": {
    "title": "Organisateurs"
  },
  "gameToValidate": {
    "title": "Jeux en attentes de validation"
  },
  "user-settings": {
    "title": "Compte",
    "past-connexion": "Connexion",
    "connexion-card": {
      "date-of-connexion": "Date de connexion",
      "browser": "Navigateur",
      "device": "Appareil",
      "authentication-successful": "Authentification réussie"
    },
    "authorization": "Authorization"
  },
  "sessions": {
    "title": "Connexion"
  },
  "users":{
    "title": "Utilisateurs",
    "expired": "Expiré",
    "blocked": "Bloqué"
  },
  "social-network": {
    "title": "Média",
    "default": "Publication par défaut",
    "reminder": "Rappel",
    "full": "Complet",
    "capacity": "Places limitées",
    "network": "Réseau",
    "too-many-publication": "Pour effectuer plus de publication contacter l'administrateur d'accrootournois.fr"
  },
  "contact": {
    "title": "Contact",
    "submit": "Envoyer un mail",
    "emailAddress": "Adresse email"
  }
}