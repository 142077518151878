import "../css/home.css"
import "../css/shine.scss"
import i18n from "i18next"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

import React, { useContext, useEffect, useState } from "react"
import { Context } from "../const/context"
import GameCard from "../component/game-card"
import { disconnect, infoToast, searchByParams, startDate } from "../const/utils"
import moment from "moment"
import "moment/locale/fr"
import {
    capitalizeFirstLetter, detail,
    gender, level,
    LEVEL,
    monthFormat,
    regionFinder, regionTranslation,
    removeObjectWithId,
    SPACE
} from "../const/common"
import { store } from "../repository/store"
import { notifierService } from "../service/notifier-service"
import { useLocation, useNavigate } from "react-router-dom"

function date(from, to) {
    const fromDate = from.locale(i18n.language)
    const fromDay = fromDate.format("D")
    const fromMonth = monthFormat(fromDate.format("MMMM"))
    const fromYear = fromDate.format("YYYY")

    const toDate = to.locale(i18n.language)
    const toDay = toDate.format("D")
    const toMonth = monthFormat(toDate.format("MMMM"))
    const toYear = toDate.format("YYYY")

    const fromFormatted = fromDay + SPACE + fromMonth + SPACE + fromYear
    const toFormatted = toDay + SPACE + toMonth + SPACE + toYear

    return fromFormatted + SPACE + " - " + toFormatted
}

function dateWithoutYear(from, to) {
    const month = window.mobileCheck ? "MMM" : "MMMM"

    const fromDate = from.locale(i18n.language)
    const fromDay = fromDate.format("D")
    const fromMonth = monthFormat(fromDate.format(month))

    const toDate = to.locale(i18n.language)
    const toDay = toDate.format("D")
    const toMonth = monthFormat(toDate.format(month))

    const fromFormatted = fromDay + SPACE + fromMonth
    const toFormatted = toDay + SPACE + toMonth

    return fromFormatted + " - " + toFormatted + SPACE + from.format("YYYY")
}

function dateWithoutMonthAndYear(from, to) {
    const month = window.mobileCheck ? "MMM" : "MMMM"

    const fromDate = from.locale(i18n.language)
    const fromDay = fromDate.format("D")
    const fromMonth = monthFormat(fromDate.format(month))
    const fromYear = fromDate.format("YYYY")

    const toDay = to.locale(i18n.language).format(" D")

    return fromDay + SPACE + " - " + toDay + SPACE + fromMonth + SPACE + fromYear
}

function gamesSorted(
    games,
    records,
    setModalUrl,
    setOffCanvasGame
) {
    const sortedGames = games.sort((first, second) => new Date(second.createdDate) - new Date(first.createdDate))

    return <React.Fragment>
        <div className="col-lg-7 col-md-10 col-sm-12 m-auto">
            { oddGamesOfWeek(sortedGames, records, setModalUrl, setOffCanvasGame) }
        </div>
    </React.Fragment>
}

function gamesByWeeks(
    games,
    userId,
    isAdmin,
    records,
    setModalUrl, setOffCanvasGame
) {
    return groupGamesByWeek(
        games.sort((first, second) => new Date(second.startDate) - new Date(first.startDate)),
        userId,
        isAdmin
    ).map(it => {
        const dates = it.id.split("-")

        const from = moment(dates[0], "YYYY/MM/DD")
        const to = moment(dates[1], "YYYY/MM/DD")

        const isSameMonth = from.month() === to.month()
        const isSameYear = from.year() === to.year()

        const dateRes = isSameMonth ? dateWithoutMonthAndYear(from, to) : isSameYear ? dateWithoutYear(from, to) : date(from, to)

        const sortedGames = it.value.sort((first, second) => moment(first.startDate) - moment(second.startDate))

        return <React.Fragment key={ it.id }>
            <div className="col-lg-7 col-md-10 col-sm-12 m-auto">
                <div className="row mb-4 mx-1">
                    <div className="col h4 border-bottom border-2 border-theme-color"></div>

                    <div className="text-center fw-semibold" style={ { maxWidth: "fit-content" } }>
                        { i18n.t("home.weekOf") } { dateRes }
                    </div>

                    <div className="col h4 border-bottom border-2 border-theme-color"></div>
                </div>

                { gamesOfWeek(sortedGames, records, setModalUrl, setOffCanvasGame) }
            </div>
        </React.Fragment>
    })
}

function groupGamesByWeek(
    games,
    userId,
    isAdmin
) {
    let gamesByWeek = []

    games.filter(it => {
        return (it.status === "ENABLE" || it.managers.includes(userId)) || isAdmin
    }).forEach(game => {
        const date = new Date(game.startDate)

        const day = date.getDay()

        const from = date.getDate() - day + (day === 0 ? -6 : 1)

        const firstDay = new Date(date.setDate(from))
        const lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + 6);

        const index = moment(firstDay).format("YYYY/MM/DD") + "-" + moment(lastDay).format("YYYY/MM/DD")

        const object = gamesByWeek.filter(it => it.id === index)[0]

        if (object != null) removeObjectWithId(gamesByWeek, index)

        const value = object == null ? [ game ] : [].concat(object.value, game)

        gamesByWeek.unshift({ id: index, value: value })
    })

    return gamesByWeek
}

function gamesOfWeek(
    games,
    records,
    setModalUrl,
    setOffCanvasGame
) {
    if (games.length === 1) return <React.Fragment> { gamesRender(games, records, setModalUrl, setOffCanvasGame) } </React.Fragment>

    if (games.length % 2 === 0) return oddGamesOfWeek(games, records, setModalUrl, setOffCanvasGame)

    const lastGame = games.pop()

    const splitGames = gamesSplitter(games)

    const first = splitGames.first
    const second = splitGames.second

    return <React.Fragment>
        <div className="row row-cols-1 row-cols-md-2">
            <div className="col-12">
                { gamesRender(first, records, setModalUrl, setOffCanvasGame) }
            </div>
            <div className="col-12">
                { gamesRender(second, records, setModalUrl, setOffCanvasGame) }
            </div>
        </div>
        <div className="col-12">
            { gameRender(lastGame, records, setModalUrl, setOffCanvasGame) }
        </div>
    </React.Fragment>
}

function gamesSplitter(games) {

    if (window.mobileCheck) return splitByMiddle(games)

    const even = []
    const odd = [];

    games.forEach((it, i, _) => {
        if ((i + 2) % 2 === 0) even.push(it)
        else odd.push(it)
    })

    return { first: even, second: odd }
}

function splitByMiddle(games) {
    const midIndex = Math.floor(games.length / 2)
    const [ first, ...second ] = [ games.slice(0, midIndex), ...games.slice(midIndex) ];
    return { first: first, second: second }
}

function oddGamesOfWeek(games, records, setModalUrl, setOffCanvasGame) {

    const splitGames = gamesSplitter(games)

    const first = splitGames.first
    const second = splitGames.second

    return <React.Fragment>
        <div className="row row-cols-1 row-cols-md-2">
            <div className="col-12">
                { gamesRender(first, records, setModalUrl, setOffCanvasGame) }
            </div>
            <div className="col-12">
                { gamesRender(second, records, setModalUrl, setOffCanvasGame) }
            </div>
        </div>
    </React.Fragment>
}

function gamesRender(value, records, setModalUrl, setOffCanvasGame) {
    return value.map(it => gameRender(it, records, setModalUrl, setOffCanvasGame))
}

function gameRender(game, records, setModalUrl, setOffCanvasGame) {
    const id = game.id

    const record = records.filter(it => it.mapperId === id)

    const url = record.length === 1 ? record[0].url : null

    return <div className="mb-4" key={ id }>
        <GameCard
            game={ game }
            url={ url }
            onHandleModalUrl={ setModalUrl }
            onHandleOffCanvasGame={ setOffCanvasGame }
        />
    </div>
}

function offCanvas(game) {

    if (game == null) return <React.Fragment></React.Fragment>

    const region = regionFinder(game.region)

    const price = game.price !== 0 ? <div
        className="text-end fw-semibold"
        style={{width: "fit-content"}}
    >
        {game.price}Є
    </div> : <React.Fragment></React.Fragment>

    return <div
        className="offcanvas offcanvas-end"
        data-bs-scroll="false"
        tabIndex="-1"
        id="offcanvas"
        aria-labelledby="offcanvasRightLabel"
    >
        <div className="offcanvas-header">
            <h5
                className="offcanvas-title"
                id="offcanvasRightLabel"
            >
                { i18n.t("common.details") }
            </h5>
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
            ></button>
        </div>
        <div className="offcanvas-body">
            <div className="card-text">
                <div className="row mb-2">
                    <div className="col">
                        <div
                            className="text-start card-title fw-bold"
                            style={ { width: "fit-content" } }
                        >{ game.name.toUpperCase() }</div>
                    </div>
                    { price }
                </div>
                <div className="small">
                    <div className="row">
                        <div className="text-start" style={ { width: "fit-content" } }>
                            <div className="fw-semibold">
                                { capitalizeFirstLetter(game.ground.toLowerCase()) }
                            </div>
                        </div>
                        <div className="col text-end">
                            <div className="fw-semibold">{ startDate(game.startDate) }</div>
                        </div>
                    </div>
                    <div className="h4 pb-2 border-bottom border-2 border-theme-color"></div>
                    <div className="row">
                        <div className="text-start" style={ { width: "fit-content" } }>
                            <div className="fw-semibold">{ game.nbOfPlayer } { i18n.t("home.players") }</div>
                        </div>
                        <div className="col text-end">
                            <div className="fw-semibold">
                                <div>{ gender(game) }</div>
                            </div>
                        </div>
                    </div>
                    <div className="h4 pb-2 border-bottom border-2 border-theme-color"></div>
                    <div className="row">
                        <div className="col-3">
                            <div className="fw-semibold">{ i18n.t("level.name") }</div>
                        </div>
                        <div className="col-9 text-end">
                            <div className="fw-semibold">{ level(game) }</div>
                        </div>
                    </div>
                    <div className="h4 pb-2 border-bottom border-2 border-theme-color"></div>
                    <div className="row">
                        <div className="col-12 text-start">
                            <button
                                className="btn btn-sm fw-semibold text-start p-0 col-12 border-0"
                                onClick={ onClipboard }
                            >
                                <div id="address" className="row p-0 m-0">
                                    <div id="address" className="d-flex justify-content-between p-0">
                                        <div id="address" className="col-10 p-0">
                                            { game.address }
                                            <br/>
                                            { regionTranslation(region) }, { capitalizeFirstLetter(region.country) }
                                        </div>
                                        <svg
                                            id="address"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16"
                                            fill="currentColor"
                                            className="bi bi-copy h-100 my-auto"
                                            viewBox="0 0 16 16"
                                        >
                                            <div hidden={ true }>{ game.address }</div>
                                            <path
                                                fillRule="evenodd"
                                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="h4 pb-2 border-bottom border-2 border-theme-color"></div>
                    <div className="row row-gap-1">
                        <div className="col-12 text-start">
                            <div className="fw-semibold">{ capitalizeFirstLetter(game.firstName.toLowerCase()) }</div>
                        </div>
                        <div className="col-12 text-start"
                             hidden={ game.phoneNumber == null || game.phoneNumber === "" }>
                            <button
                                className="btn btn-sm fw-semibold text-start p-0 col-12 border-0"
                                onClick={ onClipboard }
                            >
                                <div id="phoneNumber" className="d-flex justify-content-between p-0">
                                    <div id="phoneNumber" className="col-10">
                                        { game.phoneNumber }
                                    </div>
                                    <svg
                                        id="phoneNumber"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-copy h-100 my-auto"
                                        viewBox="0 0 16 16"
                                    >
                                        <div hidden={ true }>{ game.phoneNumber }</div>
                                        <path
                                            fillRule="evenodd"
                                            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </div>
                        <div className="col-12 text-start" hidden={ game.mail == null || game.mail === "" }>
                            <button
                                className="btn btn-sm fw-semibold text-start p-0 col-12 border-0"
                                onClick={ onClipboard }
                            >
                                <div id="mail" className="d-flex justify-content-between p-0">
                                    <div id="mail" className="col-10">
                                        { game.mail }
                                    </div>
                                    <svg
                                        id="mail"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-copy h-100 my-auto"
                                        viewBox="0 0 16 16"
                                    >
                                        <div hidden={ true }>{ game.mail }</div>
                                        <path
                                            fillRule="evenodd"
                                            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                                        />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                    { detail(game) }
                </div>
            </div>
        </div>
    </div>
}

function onClipboard(e) {
    const dataToSave = e.target.firstChild.data === undefined ? e.target.firstChild.firstChild.data : e.target.firstChild.data

    const message = i18n.t("common." + e.target.id) + SPACE + i18n.t("common.hasBeen") + SPACE + i18n.t("common.copy").toLowerCase()

    e.preventDefault()

    navigator.clipboard.writeText(dataToSave).then(_ => infoToast(message))
}

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    const isInbA = a.every(ai => b.find(bi => ai === bi))
    const isInB = b.every(bi => a.find(ai => bi === ai))

    return isInbA && isInB
}

export default function Home() {

    const href = window.location.search
    const search = href.split("?")[1]
    const params = new URLSearchParams(search)

    const sort = {
        createdDate: params.get("createdDate")
    }

    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    const [ emittedTime, setEmittedTime ] = useState(moment().format("YYYY-MM-DD HH:mm:ss"))

    useEffect(() => {

        const fetchData = async () => {
            const res = await notifierService.notifier(emittedTime)

            if (res.status === 401 || res.status === 403 || res.httpCode === 403) return disconnect(navigate, context, location.pathname)
            else if (res.status > 500) return

            res.map(it => {
                const type = it.type

                const game = it.content

                function updateGames() {
                    const isSameGame = (it) => it.id === game.id

                    const indexOfUpdatedGame = context.search.games.findIndex(isSameGame)

                    context.search.games[indexOfUpdatedGame] = game

                    setGames([ ...context.search.games ])
                }

                function updateOffCanvasGame() {
                    if (offCanvasGame == null || game.id !== offCanvasGame.id) return

                    setOffCanvasGame(game)
                }

                if (type === "GAME_UPDATED") { updateGames(); updateOffCanvasGame() }
            })

            setEmittedTime(moment().format("YYYY-MM-DD HH:mm:ss"))
        }

        const timer = setInterval( () => { fetchData().then() }, 5000)

        return () => clearInterval(timer)
    }, [ emittedTime ])

    const [ requestTime, setRequestTime ] = useState(null)
    const [ games, setGames ] = useState(null)
    const [ records, setRecords ] = useState(null)
    const [ modalUrl, setModalUrl ] = useState(null)
    const [ offCanvasGame, setOffCanvasGame ] = useState(null)

    useEffect( () => {
        async function fetchData() {

            const contextGames = context.search.games
            const contextRecords = context.search.records
            const contextRequestTime = context.search.requestTime

            if (requestTime == null) {
                const newRequestTime = moment().toDate()
                /* FIRST CHANGE REQUEST TIME TO STOP UNLESS REFRESH */
                setRequestTime(newRequestTime)

                const res = await searchByParams()

                const gamesRes = res.games
                const recordsRes = res.records

                context.search.games = gamesRes
                context.search.records = recordsRes
                context.search.requestTime = newRequestTime

                setGames([...gamesRes])
                setRecords([...recordsRes])
                setOffCanvasGame(gamesRes[0])
            } else if (moment(requestTime).isBefore(contextRequestTime)) {
                setRequestTime(contextRequestTime)
                setGames([...contextGames])
                setRecords([...contextRecords])
                setOffCanvasGame(contextGames[0])
            }
        }

        /* FIRST FETCH */
        fetchData().then()
        /* THEN FETCH EACH TIME */
        const timer = setInterval( () => { fetchData().then() }, 100)

        return () => clearInterval(timer)
    }, [ requestTime, games, records ])

    const onClickDeleteFilter = async (_) => {
        store.data.search = {
            keyword: "",
            region: "CHOOSE",
            dates: {
                startDate: moment().add(1, 'd'),
                endDate: null
            },
            level: {
                min: LEVEL.OUT_OF_COMPETITION,
                max: LEVEL.LEGEND
            },
            groundSelected: {
                indoor: true,
                beach: true,
                green: true,
                water: true,
                snow: true
            }
        }

        store.save()

        window.location.reload()
    }

    const isNoGame = games == null || games.length === 0

    if (isNoGame) return <React.Fragment>
        <div className="container text-center">
            <div
                className="col-lg-8 col-md-9 col-12 shadow m-auto p-4 bg-body-secondary rounded text-center fs-5 fw-semibold">
                { i18n.t("home.noGameFound") }
            </div>
            <button
                type="button"
                className="btn btn-danger mt-4"
                onClick={ onClickDeleteFilter }
            >{ i18n.t("home.deleteFilter") }</button>
        </div>
    </React.Fragment>

    const gamesRender = sort.createdDate === "desc" ? gamesSorted(
        games,
        records,
        setModalUrl,
        setOffCanvasGame
    ) : gamesByWeeks(games, context.user.userId, context.user.isAdmin, records, setModalUrl, setOffCanvasGame)

    return <div>
        <div>{ gamesRender }</div>
        <div
            className="modal fade"
            id="gameModal"
            tabIndex="-1"
            aria-labelledby="game-modal"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="col">
                        <div className="z-0 shine">
                            <img
                                src={ modalUrl }
                                className="card-img-top"
                                alt="tounament_image"
                            />
                        </div>
                        <div style={ { position: "absolute", top: "8px", right: "8px" } }>
                            <button
                                type="button"
                                className="z-1 btn btn-lg btn-danger shadow"
                                data-bs-dismiss="modal"
                                style={ { paddingBottom: "9px", paddingTop: "5px" } }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-x m-auto"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        { offCanvas(offCanvasGame) }
    </div>
}