import 'dotenv/config'
import '../css/hello-assos.scss'
import helloassologo from "../../public/hello_asso_logo.svg"

import React from "react"
import { generatePKCE } from "../const/pkce"

async function hash(string) {
    const utf8 = new TextEncoder().encode(string);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
}

export class ButtonHelloAsso extends React.Component {


    constructor(props) {
        super(props)

        this.onHandleClick = this.onHandleClick.bind(this)

        this.text = props.text != null ? props.text : "HelloAsso"
        this.stateParam = props.stateParam != null ? props.stateParam : ""
    }

    async onHandleClick(e) {

        const clientId = process.env.HA_CLIENT_ID

        const path = "https://auth.helloasso.com/authorize"

        const pkce = await generatePKCE()
        const codeChallenge = pkce.codeChallenge

        const clientIdParam = "client_id=" + clientId
        const redirectUriParam = "redirect_uri=" + "https://accrootournois.fr"
        const codeChallengeParam = "code_challenge=" + codeChallenge
        const codeChallengeMParam = "code_challenge_method=S256"

        const stateParam = "state=" + this.stateParam

        const param = clientIdParam + "&" + redirectUriParam + "&" + codeChallengeParam + "&" + codeChallengeMParam
        const url = path + "?" + param + "&" + stateParam

        const windowFeatures = "top=100,width=500px,height=650px"

        window.open(url, 'popup', windowFeatures)
    }


    render() {
        return <button
            className="HaAuthorizeButton shadow"
            onClick={ this.onHandleClick }
        >
            <img
                src={ helloassologo } alt="hello_asso"
                className="HaAuthorizeButtonLogo"
                style={ { width: '15%' } }
            />
            <span className="HaAuthorizeButtonTitle">{ this.text }</span>
        </button>
    }
}