import "../css/single-date-picker-wrapper.scss"

import i18n from 'i18next'
import React from "react"
import { userService } from "../service/user-service"
import { useNavigate } from 'react-router-dom'
import { DATE_FORMAT, EMAIL_REGEX, PASSWORD_REGEX } from "../const/common"
import { MAIL_VERIFICATION } from "../const/reason"
import { Captcha } from "../component/captcha"
import moment from "moment"
import { infoToast } from "../const/utils"

export default function(props) {
    const navigate = useNavigate()

    return <Registry { ...props } navigate={ navigate } />
}

class Registry extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mail: {
                isValid: true,
                value: ""
            },
            password: {
                isValid: true,
                value: "",
                hidden: true,
            },
            confirmPassword: {
                isValid: true,
                value: "",
                hidden: true,
            },
            firstName: "",
            lastName: "",
            gender: null,
            bornDate: {
                isValid: true,
                value: ""
            },
            reCaptchaToken: null,
        }

        this.navigate = this.props.navigate

        this.handleMail = this.handleMail.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
        this.handleConfirmPassword = this.handleConfirmPassword.bind(this)
        this.handleWord = this.handleWord.bind(this)
        this.handleGender = this.handleGender.bind(this)
        this.handleHiddenPassword = this.handleHiddenPassword.bind(this)
        this.handleHiddenCPassword = this.handleHiddenCPassword.bind(this)
        this.onDateChange = this.onDateChange.bind(this)
        this.onHandleReCaptchaToken = this.onHandleReCaptchaToken.bind(this)

        this.registerUser = this.registerUser.bind(this)

        this.button = this.button.bind(this)
        this.disabledButton = this.disabledButton.bind(this)
        this.enabledButton = this.enabledButton.bind(this)
    }

    handleMail(e) {
        const mail = e.target.value

        const isValid = mail === '' || mail.toLowerCase().match(EMAIL_REGEX)

        const mailState = { isValid: isValid, value: mail }

        this.setState((state, _) => ( { mail: mailState } ))
    }

    handlePassword(e) {
        const password = e.target.value

        const isValid = password === '' || password.match(PASSWORD_REGEX)

        this.setState((state, _) => ({
            password: {
                isValid: isValid,
                value: password,
                hidden: state.password.hidden
            }
        }))
    }

    handleConfirmPassword(e) {
        const confirmPassword = e.target.value
        const password = this.state['password'].value

        const isValid = confirmPassword === '' || confirmPassword === password

        this.setState((state, _) => ({
            confirmPassword: {
                isValid: isValid,
                value: confirmPassword,
                hidden: state.confirmPassword.hidden
            }
        }))
    }

    handleWord(e) {
        const id = e.target.id

        const newState = { [id]: e.target.value }

        this.setState((state, _) => ( newState ))
    }

    handleGender(e) {
        const newState = { gender: e.target.id }

        this.setState((state, _) => ( newState ))
    }

    handleHiddenPassword(e) {
        this.setState(
            (state, _) => ({
                    password: {
                        isValid: state.password.isValid,
                        value: state.password.value,
                        hidden: !state.password.hidden
                    }
                }
            )
        )
    }

    handleHiddenCPassword(e) {
        this.setState(
            (state, _) => ({
                    confirmPassword: {
                        isValid: state.confirmPassword.isValid,
                        value: state.confirmPassword.value,
                        hidden: !state.confirmPassword.hidden
                    }
                }
            )
        )
    }

    passwordType(id) {
        return this.state[id].hidden ? "password" : "text"
    }

    outsideRange() {
        return _ => { true }
    }

    isValid(key) {
        if (this.state[key].isValid) return ""
        else return "is-invalid"
    }

    onDateChange(e) {

        const date = e.target.value

        const formattedDate = moment(date, DATE_FORMAT)
        const years = new Date().getFullYear() - 18;

        const isValidDate = formattedDate.format(DATE_FORMAT) === date && formattedDate <= ( new Date().setFullYear(years) )

        const newState = { bornDate: { isValid: isValidDate, value: date } }

        this.setState((state, _) => ( newState ))
    }

    onHandleReCaptchaToken(reCaptchaToken) {
        this.setState(( { reCaptchaToken: reCaptchaToken } ))
    }

    async registerUser() {

        if (this.state.reCaptchaToken == null) {
            const myModal = new bootstrap.Modal(document.getElementById("raCaptachModal"), {})
            myModal.show()
            return
        }

        const mail = this.state['mail'].value
        const password = this.state['password'].value
        const firstName = this.state['firstName']
        const lastName = this.state['lastName']
        const gender = this.state['gender']
        const bornDate = moment(this.state['bornDate'].value)

        const reCaptchaToken = this.state.reCaptchaToken

        const data = await userService.create(
            mail,
            password,
            mail,
            firstName,
            lastName,
            gender,
            bornDate.format(DATE_FORMAT),
            reCaptchaToken
        )

        const anyError = data.error != null || data.httpCode != null

        if (anyError) return this.setState(( { reCaptchaToken: null } ), () => {})

        const callback = () => { this.navigate("/code", { state: { identifier: mail, reason: MAIL_VERIFICATION } }) }
        infoToast(i18n.t("toast.success.new-code-generated"))
        setTimeout(callback, 2000)
    }

    button() {
        const mail = this.state['mail']
        const password = this.state['password']
        const cPassword = this.state['confirmPassword']
        const firstName = this.state['firstName']
        const lastName = this.state['lastName']
        const gender = this.state['gender']
        const bornDate = this.state['bornDate']

        const isValidMail = mail.value !== '' && mail.isValid
        const isValidPassword = password.value !== '' && password.isValid
        const isValidCPassword = cPassword.value !== '' && cPassword.isValid
        const isValidFistName = firstName !== ''
        const isValidLastName = lastName !== ''
        const isValidGender = gender != null
        const isValidBornDate = bornDate.value != null && bornDate.isValid

        const isValid = isValidMail && isValidPassword && isValidCPassword && isValidFistName && isValidLastName && isValidGender && isValidBornDate

        if (!isValid) return this.disabledButton()
        else return this.enabledButton()
    }

    hiddenOrNotIcon(id) {
        const isHidden = this.state[id].hidden

        if (isHidden) return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-eye-fill"
            viewBox="0 0 16 16"
        >
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
        </svg>

        return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-eye-slash"
            viewBox="0 0 16 16"
        >
            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
        </svg>
    }

    disabledButton() {
        return <button
            className="btn btn-lg btn-primary col-12"
            onClick={ this.onclick }
            disabled
        >
            { i18n.t("registry.registry") }
        </button>
    }

    enabledButton() {
        return <button
            className="btn btn-lg btn-primary shadow col-12"
            onClick={ this.registerUser }
        >
            { i18n.t("registry.registry") }
        </button>
    }

    render() {
        return <div>
            <div id="registry" className="d-flex justify-content-center text-center">
                <main className="form-signin w-100">
                    <div className="row row-gap-3">

                        <h1 className="h3 fw-normal mb-0">{ i18n.t("registry.title") }</h1>

                        <div className="col-12 p-0">
                            <div className="form-floating shadow rounded">
                                <input
                                    type="email"
                                    className={ "form-control rounded-bottom-0 " + this.isValid("mail") }
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    value={ this.state.mail.value }
                                    onChange={ this.handleMail }
                                />
                                <label htmlFor="floatingInput">{ i18n.t("registry.mailAddress") }</label>
                            </div>
                            <div
                                className="input-group shadow rounded"
                                style={ { height: "58px" } }
                            >
                                <div className="form-floating">
                                    <input
                                        id="floatingPassword"
                                        placeholder="Password"
                                        type={ this.passwordType("password") }
                                        className={ "form-control border-bottom-1 rounded-top-0 rounded-0 mb-0 " + this.isValid("password")}
                                        value={ this.state.password.value }
                                        onChange={ this.handlePassword }
                                    />
                                    <label htmlFor="floatingPassword">{ i18n.t("common.password.name") }</label>
                                </div>
                                <div className="input-group-text rounded-0 p-0 ">
                                    <button
                                        id="hidden-password"
                                        type="button"
                                        className="btn rounded-0"
                                        style={ { width: "56px", height: "56px" } }
                                        onClick={ this.handleHiddenPassword }
                                    >
                                        { this.hiddenOrNotIcon("password") }
                                    </button>
                                </div>
                            </div>
                            <div className="input-group shadow rounded">
                                <div className="form-floating">
                                    <input
                                        type={ this.passwordType("confirmPassword") }
                                        className={ "form-control border-top-1 rounded-top-0 mb-0 " + this.isValid("confirmPassword") }
                                        id="floatingPasswordC"
                                        placeholder="PasswordC"
                                        value={ this.state.confirmPassword.value }
                                        onChange={ this.handleConfirmPassword }
                                    />
                                    <label htmlFor="floatingPasswordC">{ i18n.t("registry.passwordConfirmation") }</label>
                                </div>
                                <div className="input-group-text rounded-top-0 p-0">
                                    <button
                                        id="hidden-confirmPassword"
                                        type="button"
                                        className="btn rounded-top-0 rounded-start-0"
                                        style={ { width: "56px", height: "56px" } }
                                        onClick={ this.handleHiddenCPassword }
                                    >
                                        { this.hiddenOrNotIcon("confirmPassword") }
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 p-0">
                            <div className="form-floating shadow rounded">
                                <input
                                    type="text"
                                    className="form-control border-bottom-1 rounded-bottom-0"
                                    id="firstName"
                                    placeholder="firstName"
                                    value={ this.state.firstName }
                                    onChange={ this.handleWord }
                                />
                                <label htmlFor="firstName">{ i18n.t("common.firstname") }</label>
                            </div>
                            <div className="form-floating shadow rounded">
                                <input
                                    type="text"
                                    className="form-control border-top-1 rounded-top-0"
                                    id="lastName"
                                    placeholder="lastName"
                                    value={ this.state.lastName }
                                    onChange={ this.handleWord }
                                />
                                <label htmlFor="lastName">{ i18n.t("common.lastname") }</label>
                            </div>
                        </div>

                        <div className="btn-group p-0" role="group" aria-label="Select gender">
                            <input
                                id="MALE"
                                type="radio"
                                className="btn-check"
                                name="btnRadioGender"
                                autoComplete="off"
                                checked={ this.state.gender === "MALE" }
                                onChange={ this.handleGender }
                            />
                            <label className="btn btn-outline-primary col-4" htmlFor="MALE">{ i18n.t("common.male") }</label>

                            <input
                                id="UNKNOWN"
                                type="radio"
                                className="btn-check"
                                name="btnRadioGender"
                                autoComplete="off"
                                checked={ this.state.gender === "UNKNOWN" }
                                onChange={ this.handleGender }
                            />
                            <label className="btn btn-outline-primary col-4" htmlFor="UNKNOWN">{ i18n.t("common.other") }</label>

                            <input
                                id="FEMALE"
                                type="radio"
                                className="btn-check"
                                name="btnRadioGender"
                                autoComplete="off"
                                checked={ this.state.gender === "FEMALE" }
                                onChange={ this.handleGender }
                            />
                            <label className="btn btn-outline-primary col-4" htmlFor="FEMALE">{ i18n.t("common.female") }</label>
                        </div>

                        <div className="form-floating p-0">
                            <input
                                type="text"
                                className={ "form-control shadow " + this.isValid("bornDate") }
                                id="bornDate"
                                placeholder="bornDate"
                                value={ this.state.bornDate.value }
                                onChange={ this.onDateChange }
                            />
                            <label htmlFor="lastName">{ i18n.t("registry.bornDateInput") }</label>
                        </div>

                        { this.button() }

                        <div className="text-start p-0 mb-3">
                            <ul className="list-group shadow list-group-flush rounded">
                                <li className="list-group-item bg-body-tertiary">
                                        <span className="fw-medium text-decoration-underline">
                                            { i18n.t("common.password.name") }:
                                        </span>
                                    <br/>
                                    <span className="fst-italic">
                                            { i18n.t("common.password.rule.1SpecialCharacter") } (~, @, #, $, &)
                                        </span>
                                    <br/>
                                    <span className="fst-italic">
                                            { i18n.t("common.password.rule.1UppercaseCharacter") }
                                        </span>
                                    <br/>
                                    <span className="fst-italic">
                                            { i18n.t("common.password.rule.1LowercaseCharacter") }
                                        </span>
                                    <br/>
                                    <span className="fst-italic">
                                            { i18n.t("common.password.rule.moreThan8Characters") }
                                        </span>
                                </li>
                                <li className="list-group-item bg-body-tertiary">
                                        <span className="fw-medium text-decoration-underline">
                                            { i18n.t("registry.bornDate") }:
                                        </span>
                                    <br/>
                                    <span className="fst-italic">
                                            { i18n.t("registry.rule.bornDate.userShouldBeOlderThan18") }
                                        </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </main>
            </div>
            <Captcha onHandleToken={ this.onHandleReCaptchaToken } onClick={ this.registerUser }/>
        </div>
    }
}