import '../css/fancy.scss'

import i18n from 'i18next'
import React, { useContext } from "react"
import { Level } from "../component/level"
import { Ground } from "../component/ground"
import { InputGameEdition } from "../component/input-game-edition"
import { gameService } from "../service/game-service"
import { useLocation, useNavigate } from "react-router-dom"
import { recordService } from "../service/record-service"
import { DATE_FORMAT, genderWith, GROUND, LEVEL, levelWith } from "../const/common"
import moment from "moment"
import Login from "./login"
import { Context } from "../const/context"
import Record from "../component/record"
import { InputNumber } from "../component/input-number"
import {
    disconnect,
    isAdmin,
    isNotConnected,
    emptyableMailValidator,
    mailValidator,
    notEmptyFieldValidator,
    fullDate
} from "../const/utils"
import { Input } from "../component/input"
import { Captcha } from "../component/captcha"
import { authService } from "../service/authorization-service"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    const state = location.state

    const isNotEmptyState = state != null

    const guestMode = isNotEmptyState ? state.guestMode : false

    if (isNotConnected(context) && !guestMode) return <Login guestMode={ true }/>

    const game = isNotEmptyState ? state.game : null
    const file = isNotEmptyState ? state.file : null

    return <GameEdition
        { ...props }
        guestMode={ guestMode }
        navigate={ navigate }
        location={ location }
        context={ context }
        game={ game }
        file={ file }
    />
}

class GameEdition extends React.Component {

    constructor(props) {
        super(props);

        const game = props.game
        const file = props.file != null ? { name: game.id, preview: props.file } : null

        const isGameNotNull = game != null

        const id = isGameNotNull && game.id != null ? game.id : null
        const name = isGameNotNull && game.name != null ? game.name : ""

        const address = isGameNotNull && game.address != null ? game.address : ""
        const region = isGameNotNull && game.region != null ? game.region : "CHOOSE"

        const price = isGameNotNull && game.price != null ? game.price : ""

        const phoneNumber = isGameNotNull && game.phoneNumber != null ? game.phoneNumber : ""
        const mail = isGameNotNull && game.mail != null ? game.mail : ""

        const startDate = (isGameNotNull && game.startDate != null ? moment(game.startDate) : moment()).locale(i18n.language)
        const ground = isGameNotNull && game.ground != null ? game.ground + "-VOLLEY" : "INDOOR-VOLLEY"

        const nbOfTeam = isGameNotNull && game.nbOfTeam != null ? game.nbOfTeam : 18
        const nbOfGround = isGameNotNull && game.nbOfGround != null ? game.nbOfGround : 3
        const nbOfPlayer = isGameNotNull && game.nbOfPlayer != null ? game.nbOfPlayer : 4

        const minOfMale = isGameNotNull && game.minMaleInTeam != null ? game.minMaleInTeam : 0
        const minOfFemale = isGameNotNull && game.minFemaleInTeam != null ? game.minFemaleInTeam : 1

        const levelFrom = isGameNotNull && game.levelFrom != null ? LEVEL[game.levelFrom] : LEVEL.OUT_OF_COMPETITION
        const levelTo = isGameNotNull && game.levelTo != null ? LEVEL[game.levelTo] : LEVEL.PRO

        const detail = isGameNotNull && game.detail != null ? game.detail : ""

        this.state = {
            id: id,

            name: name,
            address: address,
            region: region,
            price: price,
            phoneNumber: phoneNumber,
            mail: mail,
            startDate: startDate,

            file: file,
            isUpdatedFile: false,

            inputIsValid: id != null,

            ground: ground,

            nbOfTeam: nbOfTeam,
            nbOfGround: nbOfGround,
            nbOfPlayer: nbOfPlayer,

            minOfMale: minOfMale,
            minOfFemale: minOfFemale,

            level: {
                min: levelFrom,
                max: levelTo
            },
            detail: detail,

            authorizationId: null,
            authorizationCode: null,

            spreadMail: "",
            reCaptchaToken: null,
            authId: null,
            code: ""
        }

        this.navigate = this.props.navigate

        this.onHandleInput = this.onHandleInput.bind(this)

        this.onHandleFile = this.onHandleFile.bind(this)

        this.onHandleNbOfTeam = this.onHandleNbOfTeam.bind(this)
        this.onHandleNbOfGround = this.onHandleNbOfGround.bind(this)
        this.onHandleNbOfPlayer = this.onHandleNbOfPlayer.bind(this)

        this.onHandleMinOfMale = this.onHandleMinOfMale.bind(this)
        this.onHandleMinOfFemale = this.onHandleMinOfFemale.bind(this)

        this.onHandleGround = this.onHandleGround.bind(this)

        this.onHandleLevel = this.onHandleLevel.bind(this)

        this.onHandleDefaultMessage = this.onHandleDefaultMessage.bind(this)

        this.onHandleDetail = this.onHandleDetail.bind(this)

        this.onHandleCancelAuth = this.onHandleCancelAuth.bind(this)
        this.onHandleInputField = this.onHandleInputField.bind(this)
        this.onHandleReCaptchaToken = this.onHandleReCaptchaToken.bind(this)
        this.onHandleAuth = this.onHandleAuth.bind(this)

        this.saveButton = this.saveButton.bind(this)

        this.saveAsGuest = this.saveAsGuest.bind(this)
        this.save = this.save.bind(this)
    }

    records() {
        const file = this.state.file

        if (file != null) return [ file ]

        return null
    }

    onHandleInput(childState) {
        this.setState((state, _) => ({
            name: childState.name,
            address: childState.address,
            region: childState.region,
            price: childState.price,
            phoneNumber: childState.phoneNumber,
            mail: childState.mail,
            startDate: childState.startDate,
            inputIsValid: childState.isValid,
        }))
    }

    async onHandleFile(file) {
        this.setState((state, _) => ({ file: file, isUpdatedFile: true }))
    }

    onHandleNbOfTeam(value) {
        this.setState((state, _) => ({ nbOfTeam: value }))
    }

    onHandleNbOfGround(value) {
        this.setState((state, _) => ({ nbOfGround: value }))
    }

    onHandleNbOfPlayer(e) {
        this.setState(
            (state, _) => ({ nbOfPlayer: parseInt(e.target.value) }),
            () => {
                const nbOfPlayer = parseInt(this.state.nbOfPlayer)

                const minOfMale = parseInt(this.state.minOfMale)
                const minOfFemale = parseInt(this.state.minOfFemale)

                const isInconstancy = (minOfMale + minOfFemale) > nbOfPlayer

                const newMinOfMale = minOfMale > 0 ? minOfMale - 1 : minOfMale
                const newMinOfFemale = minOfFemale > 0 ? minOfFemale - 1 : minOfFemale

                if (isInconstancy) this.setState(
                    (state, _) => (
                        {
                            minOfMale: newMinOfMale,
                            minOfFemale: newMinOfFemale
                        }
                    )
                )
            }
        )
    }

    onHandleMinOfMale(e) {
        const nbOfPlayer = this.state.nbOfPlayer

        const minOfMale = parseInt(e.target.value)
        const currentMinOfFemale = this.state.minOfFemale

        const diff = nbOfPlayer - minOfMale

        const minOfFemale = diff < currentMinOfFemale ? diff : currentMinOfFemale

        this.setState((state, _) => ({ minOfMale, minOfFemale }))
    }

    onHandleMinOfFemale(e) {
        const nbOfPlayer = this.state.nbOfPlayer

        const minOfFemale = parseInt(e.target.value)
        const currentMinOfMale = this.state.minOfMale

        const diff = nbOfPlayer - minOfFemale

        const minOfMale = diff < currentMinOfMale ? diff : currentMinOfMale

        this.setState((state, _) => ({ minOfMale, minOfFemale }))
    }

    onHandleGround(ground) {
        this.setState((state, _) => ({ ground }))
    }

    onHandleLevel(min, max) {
        this.setState((state, _) => ({ level: { min, max } }))
    }

    onHandleDefaultMessage(_) {
        const gender = genderWith(this.state.nbOfPlayer, this.state.minOfMale, this.state.minOfFemale)
        const level = levelWith(this.state.level.min.key, this.state.level.max.key)
        const phoneNumber = this.state.phoneNumber !== "" ? "☎️\t" + this.state.phoneNumber + "\n" : ""
        const mail = this.state.mail !== "" ? "✉️\t" + this.state.mail + "\n" : ""

        const defaultMessage = "" +
            this.state.name + "\n\n" +
            "🏟️\tTerrain " + GROUND.filter(it => it.key === this.state.ground)[0].value + "\n" +
            "📆\t" + fullDate(this.state.startDate) + "\n" +
            "💰\t" + this.state.price + "€" + "\n" +
            "⚔️\t" + this.state.nbOfPlayer + "v" + this.state.nbOfPlayer + " " + gender + "\n" +
            "🦾\t" + level + "\n" +
            phoneNumber +
            mail +
            "📍\t" + this.state.address + "\n\n" +
            "🎁 Lots a gagner 🎁\n" +
            "🍰🧃 Buvette sur place 🧃🍰"

        this.setState((state, _) => ({ detail: defaultMessage }))
    }

    onHandleDetail(e) {
        this.setState((state, _) => ({ detail: e.target.value }))
    }

    onHandleCancelAuth(_) {
        this.setState(( { spreadMail: "", reCaptchaToken: null, code: "" } ))
    }

    onHandleInputField(id, value) {
        this.setState((state, _) => ({ [id]: value }))
    }

    onHandleReCaptchaToken(reCaptchaToken) {
        this.setState(( { reCaptchaToken: reCaptchaToken } ))
    }

    async onHandleAuth(_) {
        if (this.state.reCaptchaToken == null) return this.saveAsGuest()

        const scope = [ "CREATE_GAME", "CREATE_RECORD" ]

        const spreadMail = this.state.spreadMail
        const token = this.state.reCaptchaToken

        const res = await authService.create(scope, "EMAIL", spreadMail, token)

        if (res.error != null || res.httpCode != null) {
            return this.setState(
                ( { spreadMail: "", reCaptchaToken: null, authId: "", code: "" } ),
                () => this.saveAsGuest()
            )
        }

        this.setState(
            (state, _) => ({ authId: res.id }),
            () => this.saveAsGuest()
        )
    }

    saveButton() {
        const saveIcon = <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17" height="17"
            fill="currentColor"
            className="bi bi-floppy2"
            viewBox="0 0 20 15">
            <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>
        </svg>

        const isValidFrom = this.state.inputIsValid

        if (!isValidFrom) return <button
            type="button"
            className="btn btn-secondary disabled-fancy"
            disabled
        >
            <div className="row">
                <div className="col-1">
                    { saveIcon }
                </div>
                <div className="col px-1">{ i18n.t("button.save") }</div>
            </div>
        </button>

        const guestMode = this.props.guestMode === true

        return <div className="d-flex justify-content-end">
            <div className="fancy">
                <button
                    className="btn btn-primary"
                    onClick={ guestMode ? this.saveAsGuest : this.save }
                >
                    <div className="row">
                        <div className="col-1">
                            { saveIcon }
                        </div>
                        <div className="col px-1">{ i18n.t("button.save") }</div>
                    </div>
                </button>
            </div>
        </div>
    }

    async saveAsGuest() {

        if (this.state.spreadMail === "") {
            const myModal = new bootstrap.Modal(document.getElementById("userVerificationModal"), {})
            myModal.show()
            return
        }

        if (this.state.reCaptchaToken == null) {
            const myModal = new bootstrap.Modal(document.getElementById("raCaptachModal"), {})
            myModal.show()
            return
        }

        if (this.state.code === "") {
            const myModal = new bootstrap.Modal(document.getElementById("codeVerificationModal"), {})
            myModal.show()
            return
        }

        const authId = this.state.authId
        const code = this.state.code

        const res = await gameService.createAsGuest(
            this.state.name,

            this.state.ground.split("-")[0],

            this.state.nbOfTeam,
            this.state.nbOfGround,
            this.state.nbOfPlayer,

            this.state.minOfMale,
            this.state.minOfFemale,

            this.state.level.min.key,
            this.state.level.max.key,

            this.state.startDate.format(DATE_FORMAT),

            this.state.price,

            this.state.address,
            this.state.region,

            this.state.phoneNumber,

            this.state.mail,

            this.state.detail,

            authId,
            code
        )

        if (res.error != null || res.httpCode != null) {
            return this.setState(( { spreadMail: "", reCaptchaToken: null, authId: "", code: "" } ))
        }

        const isNotUpdatedFile = !this.state.isUpdatedFile

        if (isNotUpdatedFile) return this.navigate("/success", { state: { message: i18n.t("gameEdition.congrats") } })

        const file = this.state.file

        await recordService.createAsGuest(file, "game", res.id, authId, code)

        return this.navigate("/success", { state: { message: i18n.t("gameEdition.congrats") } })
    }

    async save() {
        const createdOrUpdated = await gameService.createOrUpdate(
            this.state.id,
            this.state.name,

            this.state.ground.split("-")[0],

            this.state.nbOfTeam,
            this.state.nbOfGround,
            this.state.nbOfPlayer,

            this.state.minOfMale,
            this.state.minOfFemale,

            this.state.level.min.key,
            this.state.level.max.key,

            this.state.startDate.format(DATE_FORMAT),

            this.state.price,

            this.state.address,
            this.state.region,

            this.state.phoneNumber,

            this.state.mail,

            this.state.detail,
        )

        if (createdOrUpdated.status === 401) disconnect(this.navigate, this.props.context, this.props.location.pathname)

        if (createdOrUpdated.error != null || createdOrUpdated.httpCode != null) return this.redirect(null, true)

        const isNotUpdatedFile = !this.state.isUpdatedFile

        if (isNotUpdatedFile) return this.redirect(createdOrUpdated, isNotUpdatedFile)

        const file = this.state.file

        if (file == null && this.state.id != null) await recordService.delete("game", this.state.id)
        else if (file != null) await recordService.create(file, "game", createdOrUpdated.id)

        return this.redirect(createdOrUpdated, (isNotUpdatedFile || file == null))
    }

    redirect(game, isNotUpdatedFile) {
        if(game != null && game.status !== "UPDATED" && isNotUpdatedFile) this.navigate(-1)

        return isAdmin(this.props.context) ? this.navigate("/games-to-validate") : this.navigate("/games")
    }

    render() {
        return <div className="col-lg-9 col-md-10 col-sm-12 mx-auto p-0">

            <div className="row shadow bg-body-tertiary rounded px-3 py-2 mx-0 mb-3">
                <div className="col-7 col-md-9 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("gameEdition.title") }</p>
                </div>
                <div className="col-5 col-md-3 text-end m-auto px-0">
                    { this.saveButton() }
                </div>
            </div>

            <div className="container">
                <div className="h4 pb-2 border-bottom border-2 border-theme-color mb-4 shadow"></div>
            </div>

            <InputGameEdition
                id={ this.state.id }
                name={ this.state.name }
                address={ this.state.address }
                region={ this.state.region }
                price={ this.state.price }
                phoneNumber={ this.state.phoneNumber }
                mail={ this.state.mail }
                startDate={ this.state.startDate }
                onHandleInput={ this.onHandleInput }
            />

            <div className="my-3">
                <Record files={ this.records() } onHandleFile={ this.onHandleFile }/>
            </div>

            <div className="row row-cols-1 row-gap-3">
                <div className="col-sm-12 col-md-12 col-lg-6">
                    <InputNumber
                        label={ i18n.t("gameEdition.nbOfTeam") }
                        initialValue={ this.state.nbOfTeam }
                        minValue={ 2 }
                        onChange={ this.onHandleNbOfTeam }
                    />
                </div>

                <div className="col-sm-12 col-md-12 col-lg-6">
                    <InputNumber
                        label={ i18n.t("gameEdition.nbOfGround") }
                        initialValue={ this.state.nbOfGround }
                        minValue={ 1 }
                        onChange={ this.onHandleNbOfGround }
                    />
                </div>
            </div>

            <div className="border rounded shadow p-4 mt-3">
                <p className="text-center">{ i18n.t("gameEdition.nbOfPlayerByTeam") }: { this.state.nbOfPlayer }</p>
                <input
                    type="range"
                    className="form-range"
                    min="2"
                    max="6"
                    id="nbOfPlayer"
                    value={ this.state.nbOfPlayer }
                    onChange={ this.onHandleNbOfPlayer }
                />
            </div>

            <div className="border rounded shadow p-4 mt-3">
                <div className="row">
                    <div className="col-6 p-4">
                        <p className="text-center">{ i18n.t("gameEdition.minOfMale") }: { this.state.minOfMale }</p>
                        <input
                            type="range"
                            className="form-range"
                            min="0"
                            max={ this.state.nbOfPlayer }
                            id="minOfMale"
                            value={ this.state.minOfMale }
                            onChange={ this.onHandleMinOfMale }
                        />
                    </div>
                    <div className="col-6 p-4">
                        <p className="text-center">{ i18n.t("gameEdition.minOfFemale") }: { this.state.minOfFemale }</p>
                        <input
                            type="range"
                            className="form-range"
                            min="0"
                            max={ this.state.nbOfPlayer }
                            id="minOfFemale"
                            value={ this.state.minOfFemale }
                            onChange={ this.onHandleMinOfFemale }
                        />
                    </div>
                </div>
            </div>

            <Ground ground={ this.state.ground } onHandleGround={ this.onHandleGround }/>

            <div className="mt-3">
                <Level min={ this.state.level.min } max={ this.state.level.max } onHandleLevel={ this.onHandleLevel }/>
            </div>

            <div className="border rounded p-0 my-3">
                <div className="d-flex justify-content-between py-2">
                    <div className="ms-2">
                        <div className="d-flex">
                            <div className="form-label rounded-top m-auto p-2">
                                { i18n.t("gameEdition.detail") }
                            </div>
                            <div className="text-body-secondary m-auto fst-italic fw-lighter">{ this.state.detail.length } : 1440</div>
                        </div>
                    </div>
                    <div className="me-2 mt-1 p-0">
                        <button className="btn btn-sm btn-primary" onClick={ this.onHandleDefaultMessage }>
                            { i18n.t("gameEdition.default-message") }
                        </button>
                    </div>
                </div>
                <textarea
                    id="detail"
                    className="form-control shadow rounded-top-0 border-bottom-0 border-start-0 border-end-0"
                    rows="15"
                    value={ this.state.detail }
                    onChange={ this.onHandleDetail }
                    maxLength={ 1440 }
                ></textarea>
            </div>

            <div
                className="modal fade"
                data-bs-backdrop="static" data-bs-keyboard="false"
                id="userVerificationModal"
                tabIndex="-1"
                aria-labelledby="user-verification-modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="col-11 m-auto py-4 px-0">
                            <Input
                                id="spreadMail"
                                type="email"
                                placeholder={ i18n.t("gameEdition.email-sending-code") }
                                iconName="mail"
                                value={ this.state.spreadMail }
                                validator={ emptyableMailValidator }
                                onHandleInput={ this.onHandleInputField }
                            />
                        </div>
                        <div className="row mx-0">
                            <div className="col-6 mx-0 px-0">
                                <button
                                    type="button"
                                    className="btn btn-warning rounded-top-0 rounded-end-0 col-12"
                                    data-bs-dismiss="modal"
                                    onClick={ this.onHandleCancelAuth }
                                >{ i18n.t("button.cancel") }</button>
                            </div>
                            <div className="col-6 mx-0 px-0">
                                <button
                                    type="button"
                                    className="btn btn-secondary rounded-top-0 rounded-start-0 col-12"
                                    data-bs-dismiss="modal"
                                    onClick={ this.saveAsGuest }
                                    disabled={ !mailValidator(this.state.spreadMail) }
                                >{ i18n.t("button.send") }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Captcha onHandleToken={ this.onHandleReCaptchaToken } onClick={ this.onHandleAuth }/>

            <div
                className="modal fade"
                data-bs-backdrop="static" data-bs-keyboard="false"
                id="codeVerificationModal"
                tabIndex="-1"
                aria-labelledby="code-verification-modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="col-11 m-auto py-4 px-0">
                            <Input
                                id="code"
                                type="text"
                                placeholder={ i18n.t("gameEdition.code-send-by-mail") }
                                iconName="code"
                                value={ this.state.code }
                                validator={ notEmptyFieldValidator }
                                onHandleInput={ this.onHandleInputField }
                            />
                        </div>
                        <div className="row mx-0">
                            <div className="col-6 mx-0 px-0">
                                <button
                                    type="button"
                                    className="btn btn-warning rounded-top-0 rounded-end-0 col-12"
                                    data-bs-dismiss="modal"
                                    onClick={ this.onHandleCancelAuth }
                                >{ i18n.t("button.cancel") }</button>
                            </div>
                            <div className="col-6 mx-0 px-0">
                                <button
                                    type="button"
                                    className="btn btn-secondary rounded-top-0 rounded-start-0 col-12"
                                    data-bs-dismiss="modal"
                                    onClick={ this.saveAsGuest }
                                    disabled={ !notEmptyFieldValidator(this.state.code) }
                                >{ i18n.t("button.save") }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}