import "dotenv/config"

import { APPLICATION_JSON, DELETE, GET, POST, PUT } from "../const/request"
import { store } from "../repository/store"
import { resParser } from "../const/utils"

class GameService {

    async createAsGuest(
        name,
        ground,

        nbOfTeam,
        nbOfGround,
        nbOfPlayer,

        minMaleInTeam,
        minFemaleInTeam,

        levelFrom,
        levelTo,

        startDate,

        price,

        address,
        region,

        phoneNumber,
        mail,

        detail,

        authorizationId,
        authorizationCode
    ) {
        const body = JSON.stringify({
            name: name,
            ground: ground,

            nbOfTeam: nbOfTeam,
            nbOfGround: nbOfGround,

            nbOfPlayer: nbOfPlayer,

            minMaleInTeam: minMaleInTeam,
            minFemaleInTeam: minFemaleInTeam,

            levelFrom: levelFrom,
            levelTo: levelTo,

            startDate: startDate,

            price: price,

            address: address,
            region: region,

            phoneNumber: phoneNumber,
            mail: mail,

            detail: detail
        })

        const path = process.env.URL + "/api/v1/games/as-guest"

        const headers = {
            "Content-Type": APPLICATION_JSON,
            "Authorization-Id": authorizationId,
            "Authorization-Code": authorizationCode
        }

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body: body,
        })

        return await resParser(response)
    }

    async createOrUpdate(
        id,

        name,
        ground,

        nbOfTeam,
        nbOfGround,
        nbOfPlayer,

        minMaleInTeam,
        minFemaleInTeam,

        levelFrom,
        levelTo,

        startDate,

        price,

        address,
        region,

        phoneNumber,
        mail,

        detail
    ) {
        const auth = "Bearer " + store.data.token.access.value

        const body = JSON.stringify({
            name: name,
            ground: ground,

            nbOfTeam: nbOfTeam,
            nbOfGround: nbOfGround,

            nbOfPlayer: nbOfPlayer,

            minMaleInTeam: minMaleInTeam,
            minFemaleInTeam: minFemaleInTeam,

            levelFrom: levelFrom,
            levelTo: levelTo,

            startDate: startDate,

            price: price,

            address: address,
            region: region,

            phoneNumber: phoneNumber,
            mail: mail,

            detail: detail
        })

        const isNotNullId = id != null

        const defaultPath = process.env.URL + "/api/v1/games"
        const path = isNotNullId ? defaultPath + "/" + id : defaultPath
        const method = isNotNullId ? PUT : POST

        const response = await fetch(path, {
            method: method,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body: body,
        })

        return await resParser(response)
    }

    async getAll(
        keyword,
        statuses,
        isMixed,
        grounds,
        startDate,
        endDate,
        levelFrom,
        levelTo,
        regions,
        managerIds,
        page,
        size
    ) {
        const auth = "Bearer " + store.data.token.access.value

        let path = process.env.URL + "/api/v1/games?page=0&size=100"

        if (keyword != null && keyword !== "") path = path + "&keyword=" + keyword
        if (statuses != null && statuses !== "") path = path + "&statuses=" + statuses
        if (managerIds != null && managerIds !== "") path = path + "&managerIds=" + managerIds

        const response = await fetch(path, {
            method: GET,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
        })

        return await resParser(response)
    }

    async search(
        keyword,
        isMixed,
        grounds,
        startDate,
        endDate,
        levelFrom,
        levelTo,
        regions,
        page,
        size
    ) {
        const body = JSON.stringify({
            keyword: keyword,
            isMixed: isMixed,
            grounds: grounds,
            startDate: startDate,
            endDate: endDate,
            levelFrom: levelFrom,
            levelTo: levelTo,
            regions: regions,
            page: page, size: size
        })

        const path = process.env.URL + "/api/v1/games/search"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body: body
        })

        return await resParser(response)
    }

    async updateStatus(id, status) {
        const auth = "Bearer " + store.data.token.access.value

        const body = JSON.stringify({ status: status })

        const path = process.env.URL + "/api/v1/games/" + id + "/status"

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
            body: body
        })

        return await resParser(response)
    }

    async updateManager(id, userId, action) {
        const auth = "Bearer " + store.data.token.access.value

        const body = JSON.stringify({ userToGrant: { [action]: userId } })

        const path = process.env.URL + "/api/v1/games/" + id + "/managers"

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON, "Authorization": auth },
            body: body
        })

        return await resParser(response)
    }

    async delete(id) {
        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.URL + "/api/v1/games/" + id

        const response = await fetch(path, {
            method: DELETE,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
        })

        return await resParser(response)
    }
}

export const gameService = new GameService()