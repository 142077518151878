import '../css/login.css'

import i18n from 'i18next'
import React, { useContext } from "react"
import { tokenService } from "../service/token-service"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { store } from "../repository/store"
import { MAIL_VERIFICATION, PASSWORD_TO_RESET } from "../const/reason"
import { Captcha } from "../component/captcha"
import { Context } from "../const/context"
import { parseJwt } from "../const/common"
import { ButtonHelloAsso } from "../component/button-hello-asso"
import { successToast } from "../const/utils"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    return <Login { ...props } navigate={ navigate } location={ location } context={ context } />
}

class Login extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = this.props.navigate

        const remember = store.data.user.remember

        const identifier= remember && store.data.user.identifier ? store.data.user.identifier : ""
        const password = remember && store.data.user.password ? store.data.user.password : ""

        this.state = {
            identifier: identifier,
            password: {
                value: password,
                hidden: true
            },
            remember: remember,
            reCaptchaToken: null,
        }

        this.handleIdentifier = this.handleIdentifier.bind(this)
        this.handlePassword = this.handlePassword.bind(this)
        this.handleHidden = this.handleHidden.bind(this)
        this.handleRemember = this.handleRemember.bind(this)
        this.onHandleReCaptchaToken = this.onHandleReCaptchaToken.bind(this)

        this.button = this.button.bind(this)
        this.disabledButton = this.disabledButton.bind(this)
        this.enabledButton = this.enabledButton.bind(this)
        this.hiddenOrNotIcon = this.hiddenOrNotIcon.bind(this)

        this.onclick = this.onclick.bind(this)
        this.onHandleGameEditionInGuestMode = this.onHandleGameEditionInGuestMode.bind(this)
        this.passwordForgotten = this.passwordForgotten.bind(this)
        this.accountValidation = this.accountValidation.bind(this)
        this.updateRemember = this.updateRemember.bind(this)
    }

    handleIdentifier(e) {
        this.setState(
            (state, _) => ( { identifier: e.target.value } ),
            () => this.updateRemember()
        )
    }

    handlePassword(e) {
        this.setState(
            (state, _) => ({
                password: {
                    value: e.target.value,
                    hidden: state.password.hidden
                }
            }),
            () => this.updateRemember()
        )
    }

    handleHidden(_) {
        this.setState(
            (state, _) => ({
                    password: {
                        value: state.password.value, hidden: !state.password.hidden
                    }
                }
            )
        )
    }

    handleRemember(_) {
        this.setState(
            (state, _) => ( { remember: !state.remember } ),
            () => this.updateRemember()
        )
    }

    onHandleReCaptchaToken(reCaptchaToken) {
        this.setState(( { reCaptchaToken: reCaptchaToken } ))
    }

    button() {
        const isValidId = this.state.identifier !== ""
        const isValidPassword = this.state.password.value !== ""

        const isValid = isValidId && isValidPassword

        if (!isValid) return this.disabledButton()
        else return this.enabledButton()
    }

    guestMode() {
        return this.props.guestMode ? <button
            className="btn btn-link fw-medium rounded-2"
            onClick={ this.onHandleGameEditionInGuestMode }
        >
            { i18n.t("login.continue-as-guest") }
        </button> : <React.Fragment></React.Fragment>
    }

    disabledButton() {
        return <button
            className="btn btn-lg btn-primary col-12"
            onClick={ this.onclick }
            disabled={ true }
        >
            { i18n.t("login.signIn") }
        </button>
    }

    enabledButton() {
        return <button
            className="btn btn-lg btn-primary shadow col-12"
            onClick={ this.onclick }
        >
            { i18n.t("login.signIn") }
        </button>
    }

    hiddenOrNotIcon() {
        if (this.state.password.hidden) return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-eye-fill"
            viewBox="0 0 16 16"
        >
            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
        </svg>

        return <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-eye-slash"
            viewBox="0 0 16 16"
        >
            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
        </svg>
    }

    passwordType() {
        return this.state.password.hidden ? "password" : "text"
    }

    async onclick() {

        if (this.state.reCaptchaToken == null) {
            const myModal = new bootstrap.Modal(document.getElementById("raCaptachModal"), {})
            myModal.show()
            return
        }

        const identifier = this.state.identifier
        const password = this.state.password.value
        const reCaptchaToken = this.state.reCaptchaToken

        const data = await tokenService.login(identifier, password, reCaptchaToken)

        if (data.code != null && data.code.value === "USER_VERIFICATION_FAILED") return this.navigate(
            "/code",
            { state: { identifier, reason: MAIL_VERIFICATION } }
        )
        if (data.error != null || data.httpCode != null) return this.setState(( { reCaptchaToken: null } ))

        const access = data.access
        const refresh = data.refresh

        store.data.token.access.value = access.value
        store.data.token.access.deadline = access.deadline
        store.data.token.refresh.value = refresh.deadline
        store.data.token.refresh.deadline = refresh.deadline
        store.save()

        const parsedJwt = parseJwt(store.data.token.access.value)

        this.props.context.user.isConnected = true
        this.props.context.user.userId = parsedJwt.id
        this.props.context.user.isAdmin = parsedJwt.roles.includes("ADMIN")

        const location = this.props.location.pathname

        successToast(i18n.t("toast.success.connected"))

        if (location === "/login") this.navigate("/")
        else this.navigate(location)

        setTimeout(function() { window.console.clear() }, 500)
    }

    onHandleGameEditionInGuestMode() {
        this.navigate("/game-edition", { state: { guestMode: true } })
    }

    updateRemember() {
        if (this.state.remember) {
            store.data.user.identifier = this.state.identifier
            store.data.user.password = this.state.password.value
            store.data.user.remember = true
        } else {
            store.data.user.identifier = ""
            store.data.user.password = ""
            store.data.user.remember = false
        }
        store.save()
    }

    passwordForgotten() {
        this.navigate(
            "/identifier",
            { state: { reason: PASSWORD_TO_RESET } }
        )
    }

    accountValidation() {
        this.navigate(
            "/identifier",
            { state: { reason: MAIL_VERIFICATION } }
        )
    }

    render() {
        return <div>
            <div id="login" className="d-flex justify-content-center text-center">
                <main className="form-signin w-100">
                    <div className="row row-gap-3">
                        <h1 className="h3 fw-normal mb-0">{ i18n.t("login.title") }</h1>

                        <div className="col-12 p-0">
                            <div className="form-floating shadow">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="name@example.com"
                                    value={ this.state.identifier }
                                    onChange={ this.handleIdentifier }
                                />
                                <label htmlFor="floatingInput">{ i18n.t("common.identifier") }</label>
                            </div>

                            <div className="input-group shadow rounded">
                                <div className="form-floating">
                                    <input
                                        type={ this.passwordType() }
                                        className="form-control mb-0"
                                        id="floatingPassword"
                                        placeholder="Password"
                                        value={ this.state.password.value }
                                        onChange={ this.handlePassword }
                                    />
                                    <label htmlFor="floatingPassword">{ i18n.t("common.password.name") }</label>
                                </div>
                                <div className="input-group-text rounded-top-0 p-0">
                                    <button
                                        type="button"
                                        className="btn rounded-top-0 rounded-start-0"
                                        style={ { width: "56px", height: "56px" } }
                                        onClick={ this.handleHidden }
                                    >
                                        { this.hiddenOrNotIcon() }
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="checkbox">
                            <label>
                                <input
                                    checked={ this.state.remember }
                                    type="checkbox"
                                    onChange={ this.handleRemember }
                                /> { i18n.t("login.rememberMe") }
                            </label>
                        </div>

                        { this.button() }

                        { this.guestMode() }

                        <div>
                            <div className="fw-medium align-text-bottom">
                                <p className="fw-medium p-0 m-0">
                                    { i18n.t("common.new") }? <Link className="link" to="/registry">{ i18n.t("login.createAccount") }</Link>
                                </p>
                                <button className="btn btn-link fw-medium p-0 m-0"
                                        onClick={ this.passwordForgotten }>{ i18n.t("login.passwordForgotten") }?
                                </button>
                                <span className="align-middle"> { i18n.t("common.or") }</span>
                                <button
                                    className="btn btn-link fw-medium p-0 m-0"
                                    onClick={ this.accountValidation }
                                >
                                    { i18n.t("login.validAccount") }
                                </button>
                            </div>
                        </div>

                        <div style={ { fontSize: "11px" } }>
                            This site is protected by reCAPTCHA and the Google <a
                            href="https://policies.google.com/privacy">Privacy Policy</a> and <a
                            href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </div>

                        {/*<div className="container">*/}
                        {/*    <div className="h4 pb-2 border-bottom border-theme-color mb-2 shadow"></div>*/}
                        {/*</div>*/}

                        {/*<ButtonHelloAsso text="Se connecter avec HelloAsso"/>*/}
                    </div>
                </main>
            </div>
            <Captcha onHandleToken={ this.onHandleReCaptchaToken } onClick={ this.onclick }/>
        </div>
    }
}