import 'dotenv/config'
import i18n from 'i18next';

import React from 'react'
import ReCAPTCHA from "react-google-recaptcha"

export class Captcha extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabledButton: true
        }

        this.id = this.props.reCaptchaModalId != null ? this.props.reCaptchaModalId : "raCaptachModal"
        this.captcha = null

        this.onHandleChange = this.onHandleChange.bind(this)
        this.onHandleClose = this.onHandleClose.bind(this)
    }

    onHandleChange(e) {
        this.props.onHandleToken(e)

        this.setState(( { disabledButton: false } ))
    }

    onHandleClose(e) {
        this.captcha.reset()

        this.setState(( { disabledButton: true } ), () => this.props.onClick())
    }

    render() {
        return (
            <div
                className="modal fade"
                data-bs-backdrop="static" data-bs-keyboard="false"
                id={ this.id }
                tabIndex="-1"
                aria-labelledby="re-captcha-modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header m-auto border-0 pt-4 pb-4">
                            <ReCAPTCHA
                                sitekey={ process.env.RE_CAPTCHA_KEY }
                                ref={ e => (this.captcha = e) }
                                onChange={ this.onHandleChange }
                                hl={ i18n.language }
                            />
                        </div>
                        <button
                            id={ "btn-" + this.id }
                            type="button"
                            className="btn btn-secondary rounded-top-0"
                            data-bs-dismiss="modal"
                            onClick={ this.onHandleClose }
                            disabled={ this.state.disabledButton }
                        >{ i18n.t("common.confirm") }</button>
                    </div>
                </div>
            </div>
        )
    }
}