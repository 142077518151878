import i18n from "i18next"

import React from "react"
import { actionButton } from "../const/common"
import Showcase from "./showcase"

function backgroundColor(ground) {
    switch (ground) {
        case "INDOOR": return "text-bg-custom"
        case "GREEN": return "bg-success"
        case "BEACH": return "bg-warning"
        case "WATER": return "bg-primary"
        case "SNOW": return "bg-info"
    }
}

function deleteButton(game) {
    const target = "#modalGameDelete" + game.id

    return <button
        type="button"
        className="btn btn-danger btn-sm shadow col-12"
        data-bs-toggle="modal"
        data-bs-target={ target }
        data-bs-whatever="@mdo"
    >
        { i18n.t("button.delete") }
    </button>
}

function updateButton(game, url, onHandleUpdate) {
    return <div className="col-12 mt-2 mb-2">
        <button
            type="button"
            className="btn btn-light btn-sm shadow col-12"
            onClick={() => onHandleUpdate(game, url)}
        >
            { i18n.t("button.update") }
        </button>
    </div>
}

function gameFooter(game, url, context, onHandleStatusChange, onHandleUpdate) {
    const isUserConnected = context.user.isConnected

    if (!isUserConnected) return <React.Fragment></React.Fragment>

    const isManager = game.managers.includes(context.user.userId)

    if (!(context.user.isAdmin || isManager)) return <React.Fragment></React.Fragment>

    return <div className="px-1 rounded">
        <div className="row">
            <div className="col-6 mt-2 pe-1">
                { actionButton(game, context, onHandleStatusChange, "btn-light") }
            </div>
            <div className="col-6 mt-2 ps-1">
                { deleteButton(game) }
            </div>
        </div>
        { updateButton(game, url, onHandleUpdate) }
    </div>
}

export default function GameCard(props) {
    const game = props.game
    const url = props.url
    const onHandleModalUrl = props.onHandleModalUrl
    const onHandleOffCanvasGame = props.onHandleOffCanvasGame

    return <div className={ "card shadow border-0 " + backgroundColor(game.ground) }>
        <Showcase
            game={ game }
            url={ url }
            onHandleModalUrl={ onHandleModalUrl }
            onHandleOffCanvasGame={ onHandleOffCanvasGame }
            padding={ "1px" }
        />
    </div>
}