import i18n from "i18next"

import React, { useContext } from "react"
import { disconnect, isNotConnected } from "../const/utils"
import { userService } from "../service/user-service"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../const/context"
import Login from "./login"
import { capitalizeFirstLetter, pictureByGender } from "../const/common"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    if (isNotConnected(context)) return <Login/>

    return <Users { ...props } navigate={ navigate } location={ location } context={ context }/>
}

class Users extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = this.props.navigate

        this.state = {
            keyword: "",
            users: []
        }

        this.getAll = this.getAll.bind(this)

        this.onHandleKeyword = this.onHandleKeyword.bind(this)
        this.onHandleDeleteKeyword = this.onHandleDeleteKeyword.bind(this)
        this.onHandleDisable = this.onHandleDisable.bind(this)
    }

    componentDidMount() {
        this.getAll()
    }

    getAll(keyword) {
        userService.getAll(keyword).then(it => {

            if (it.status === 401) return disconnect(this.navigate, this.props.context, this.props.location.pathname)
            if (it.status === 403) return this.navigate("/home")

            this.setState((state, _) => ({ users: it }))
        })
    }

    onHandleKeyword(e) {
        const keyword = e.target.value
        this.setState(
            (state, _) => ({ keyword: keyword }),
            () => { this.getAll(keyword) }
        )
    }

    onHandleDeleteKeyword(_) {
        const currentKeyword = this.state.keyword

        this.setState(
            (state, _) => ({ keyword: "" }),
            () => {
                if (this.state.keyword !== currentKeyword) this.getAll()
            }
        )
    }

    async onHandleDisable(user) {
        const res = await userService.updateLock(user.id, !user.accountLocked)

        if (res.status === 401) return disconnect(this.navigate, this.props.context, this.props.location.pathname)
        else if (res.status === 403) return this.navigate("/home")
        else if (res.error != null || res.httpCode != null) return

        const users = this.state.users

        const index = users.findIndex(it => it.id === res.id)

        users[index] = res

        this.setState(() => ({ users: users }))
    }

    users() {
        return this.state.users.reverse().map(it => <React.Fragment key={ it.id }>{ this.userDetail(it) }</React.Fragment>)
    }

    yesOrNot(value) {
        return value ? "Oui" : "Non"
    }

    status = (isEnable) => {
        if (isEnable) return <button
            type="button"
            className="btn btn-outline-success btn-sm active col-12"
        >
            { i18n.t("button.activate") }
        </button>

        return <button
            type="button"
            className="btn btn-outline-warning btn-sm active col-12"
        >
            { i18n.t("button.disabled") }
        </button>
    }

    lockButton(user) {
        if (user.accountLocked) return <button
            className="btn btn-success btn-sm col-12"
            onClick={ () => this.onHandleDisable(user) }
        >
            { i18n.t("button.activate") }
        </button>

        return <button
            className="btn btn-warning btn-sm col-12"
            onClick={ () => this.onHandleDisable(user) }
        >
            { i18n.t("button.disable") }
        </button>
    }

    async onHandleNavigateAccount(id) {
        const user = await userService.getById(id)

        this.navigate("/user-settings", { state: { user: user } })
    }

    userDetail(user) {
        return <div className="modal-content rounded-2 shadow fw-medium bg-body-tertiary p-4 my-4">
            <div className="row row-gap-3">
                <div className="col-12 col-md-3 col-lg-2">
                    <div className="row row-gap-3 m-auto">
                        { this.status(user.isEnable) }
                        <div className="border rounded p-2">
                            <img
                                src={ pictureByGender(user.gender) }
                                className="img-thumbnail border-0 bg-transparent"
                                alt="user_image"
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-9 col-lg-10 px-0">
                    <div className="row row-gap-3 px-0 mx-0">
                        <div className="row row-gap-3 row-cols-3 m-0 p-0">
                            <div className="col-12 col-md-6">
                                <button
                                    className="btn btn-secondary btn-sm col-12"
                                    onClick={ () => this.onHandleNavigateAccount(user.id) }
                                >
                                    { i18n.t("button.update") }
                                </button>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="row">
                                    <div className="col-6">
                                        { this.lockButton(user) }
                                    </div>
                                    <div className="col-6">
                                        <button
                                            className="btn btn-danger btn-sm col-12"
                                            data-bs-toggle="modal"
                                            // data-bs-target={ "#modalGameDelete" + it.id }
                                            // data-bs-whatever="@mdo"
                                            disabled
                                        >
                                            { i18n.t("button.delete") }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row-gap-1 m-0 p-0">
                            <div>
                                <div className="d-flex justify-content-between small">
                                    <div>
                                        { user.firstName } { user.lastName }
                                    </div>
                                    <div>
                                        { user.username }
                                    </div>
                                </div>
                                <div className="h4 pb-2 border-bottom border-2 border-theme-color"></div>
                            </div>
                            <div className="row row-gap-1 m-auto px-0 small">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        { user.mail }
                                    </div>
                                    <div>
                                        { user.bornDate }
                                    </div>
                                </div>
                                <div className="row row-gap-1 m-0 p-0">
                                    <div>
                                        { capitalizeFirstLetter(user.gender.toLowerCase()) }
                                    </div>
                                    <div>
                                        { i18n.t("users.expired") }: { this.yesOrNot(user.accountExpired) }
                                    </div>
                                    <div>
                                        { i18n.t("users.blocked") }: { this.yesOrNot(user.accountLocked) }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    render() {
        return <div className="col-lg-9 col-md-10 col-sm-12 mx-auto">

            <div className="mb-3">
                <p className="fs-2 m-0 bg-body-tertiary shadow rounded px-3 py-2">{ i18n.t("users.title") }</p>
            </div>

            <div className="container">
                <div className="h4 pb-2 border-bottom border-2 border-theme-color mb-4 shadow"></div>
            </div>

            <div className="input-group shadow rounded col-12">
                <input
                    className="form-control"
                    type="text"
                    placeholder={ i18n.t("sideBar.keyword") }
                    value={ this.state.keyword }
                    onChange={ this.onHandleKeyword }
                />
                <button
                    id="deleteField-keyword"
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={ this.onHandleDeleteKeyword }
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-x-lg"
                        viewBox="0 0 16 16"
                        style={ { pointerEvents: "none" } }
                    >
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                </button>
            </div>

            { this.users() }

        </div>
    }
}