import { store } from "../repository/store"
import { APPLICATION_JSON, GET, POST } from "../const/request"
import { resParser } from "../const/utils"

class MediaService {

    async create(
        documentName,
        documentId,
        networks,
        content
    ){

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.URL + "/api/v1/documents/" + documentName + "-" + documentId + "/medias"

        const body = JSON.stringify({ networks: networks, content: content })

        const response = await fetch(path, {
            method: POST,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body: body
        })

        return await resParser(response)
    }

    async getAll(
        documentName,
        documentId
    ) {

        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.URL + "/api/v1/documents/" + documentName + "-" + documentId + "/medias"

        const response = await fetch(path, {
            method: GET,
            headers: { "Authorization": auth }
        })

        return await resParser(response)
    }

}

export const mediaService = new MediaService()