import "dotenv/config"
import { APPLICATION_JSON, POST } from "../const/request"
import { resParser } from "../const/utils"

class TokenService {

    async login(
        username,
        password,
        reCaptchaToken
    ) {
        const body = JSON.stringify({
            identifier: username,
            password: password,
            reCaptchaToken: reCaptchaToken
        })

        const path = process.env.URL + "/api/v1/auth/token"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body,
        })

        return await resParser(response)
    }
}

export const tokenService = new TokenService()