import '../css/custom.scss'
import logo from "../../public/logo.png"

import i18n from 'i18next'
import React, { useContext } from "react"
import { store } from "../repository/store"
import { DateRangePicker } from "react-dates"
import moment from "moment"
import {
    COUNTRY,
    DATE_FORMAT,
    LEVEL,
    REGION,
    parseJwt,
    regionByCountry,
    country,
    NESTED_NAV_LINK_STYLE
} from "../const/common"
import { Level } from "../component/level"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../const/context"
import { disconnect, handleMachineTheme, isNotConnected, searchByParams } from "../const/utils"
import { userService } from "../service/user-service"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    return <Navbar { ...props } navigate={ navigate } location={ location } context={ context } />
}

class Navbar extends React.Component {

    constructor(props) {
        super(props)

        /* TO REMOVE */
        const data = store.data

        delete data.theme
        delete data.screen

        store.data = data
        store.save()
        /* TO REMOVE */

        const user = store.data.user
        const themeToSave = user.theme === undefined || user.theme === "" ? "auto" : user.theme
        const theme = themeToSave === "auto" ? handleMachineTheme() : themeToSave

        const token = store.data.token.access
        const search = store.data.search
        const dates = search.dates
        const level = search.level
        const groundSelected = search.groundSelected

        const startDateFromLS = dates.startDate != null ? moment(dates.startDate, DATE_FORMAT) : null
        const endDateFromLS = dates.endDate != null ? moment(dates.endDate, DATE_FORMAT) : null

        const now = moment()

        const isStartDateInFuture = startDateFromLS > now

        const startDate = isStartDateInFuture ? startDateFromLS : now

        const isEndDateAfterStartDate = endDateFromLS >= startDate

        const endDate = isEndDateAfterStartDate ? endDateFromLS : null

        if (themeToSave !== user.theme) {
            store.data.user.theme = themeToSave
            store.save()
        }

        if (!isStartDateInFuture || !isEndDateAfterStartDate) {
            store.data.search.dates.startDate = startDate
            store.data.search.dates.endDate = endDate
            store.save()
        }

        const minLevel = level != null ? level.min : LEVEL.OUT_OF_COMPETITION
        const maxLevel = level != null ? level.max : LEVEL.LEGEND

        document.getElementById("body").setAttribute("data-bs-theme", theme)

        const region = search.region

        const country = region !== undefined && region !== "CHOOSE" ? REGION.filter(it => it.key === region)[0].country : "CHOOSE"

        this.state = {
            keyword: search.keyword,
            country: country,
            region: region,
            startDate: startDate.locale(i18n.language),
            endDate: endDate,
            level: {
                min: minLevel,
                max: maxLevel
            },
            focusedInput: null,

            indoor: groundSelected.indoor,
            beach: groundSelected.beach,
            green: groundSelected.green,
            water: groundSelected.water,
            snow: groundSelected.snow
        };

        this.navigate = this.props.navigate

        this.screenWith = window.innerWidth

        const isAuth = token.value != null || token.deadline != null
        const isNotExpired =  moment(token.deadline) >= moment()

        const parsedJwt = isAuth ? parseJwt(token.value) : null

        this.props.context.user.isConnected = isAuth && isNotExpired
        this.props.context.user.userId = parsedJwt != null ? parsedJwt.id : null
        this.props.context.user.isAdmin = parsedJwt != null ? parsedJwt.roles.includes("ADMIN") : false

        this.dateOnDatesChange = this.dateOnDatesChange.bind(this)
        this.dateOnFocusChange = this.dateOnFocusChange.bind(this)

        this.button = this.button.bind(this)
        this.loginButton = this.loginButton.bind(this)
        this.userButton = this.userButton.bind(this)

        this.onHandleLogout = this.onHandleLogout.bind(this)

        this.onHandleKeyword = this.onHandleKeyword.bind(this)
        this.onHandleCountry = this.onHandleCountry.bind(this)
        this.onHandleRegion = this.onHandleRegion.bind(this)

        this.onHandleLevel = this.onHandleLevel.bind(this)

        this.onHandleCountryDelete = this.onHandleCountryDelete.bind(this)
        this.onHandleFieldDelete = this.onHandleFieldDelete.bind(this)

        this.handleGroundSelected = this.handleGroundSelected.bind(this)

        this.onHandleNavigate = this.onHandleNavigate.bind(this)
        this.onHandleNavigateAccount = this.onHandleNavigateAccount.bind(this)

        this.search = this.search.bind(this)

        this.groundButton = this.groundButton.bind(this)
    }

    onHandleLogout() {
        disconnect(this.navigate, this.props.context, this.props.location.pathname)
    }

    button() {
        if (isNotConnected(this.props.context)) return this.loginButton()

        return this.userButton()
    }


    isConnected() {
        const isConnected = this.props.context.user.isConnected
        const access = store.data.token.access
        const refresh = store.data.token.refresh

        return isConnected && access.value != null && refresh.value != null
    }

    userButton() {
        return <ul className="navbar-nav me-auto mb-lg-0">
            <li className="nav-item dropdown">
                <a
                    className="nav-link dropdown-toggle"
                    href="#" role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >{ i18n.t("navBar.account.name") }</a>
                <ul className="dropdown-menu bg-body-tertiary" style={ { maxWidth: "100%" } }>
                    <li className="nav-item">
                        <button
                            id="user-settings"
                            onClick={ this.onHandleNavigateAccount }
                            className="nav-link"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            style={ NESTED_NAV_LINK_STYLE }
                        >{ i18n.t("navBar.account.setting") }</button>
                    </li>
                    <li className="nav-item">
                        <button
                            id="games-all"
                            onClick={ this.onHandleLogout }
                            className="nav-link"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            style={ NESTED_NAV_LINK_STYLE }
                        >{ i18n.t("navBar.account.logout") }</button>
                    </li>
                </ul>
            </li>
        </ul>
    }

    loginButton() {
        return <ul className="navbar-nav me-auto mb-lg-0">
            <li className="nav-item">
                <button
                    id="login"
                    onClick={ this.onHandleNavigate }
                    className="nav-link"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >
                    { i18n.t("navBar.login") }
                </button>
            </li>
        </ul>
    }

    dateOnDatesChange() {
        return ({ startDate, endDate }) => this.setState(
            { startDate, endDate },
            () => {
                store.data.search.dates.startDate = this.state.startDate
                store.data.search.dates.endDate = this.state.endDate
                store.save()
            }
        )
    }

    dateOnFocusChange() {
        return focusedInput => this.setState({ focusedInput })
    }

    onHandleLevel(min, max) {
        this.setState(
            (state, _) => ({ level: { min, max } }),
            () => {
                store.data.search.level.min = this.state.level.min
                store.data.search.level.max = this.state.level.max
                store.save()
            }
        )
    }

    datePickerWidth() {
        if (this.screenWith <= 360) return 39
        if (this.screenWith <= 376) return 41
        if (this.screenWith <= 391) return 43
        if (this.screenWith <= 394) return 44

        return 45
    }

    onHandleKeyword(e) {
        this.setState(
            (state, _) => ({ keyword: e.target.value }),
            () => {
                store.data.search.keyword = this.state.keyword
                store.save()
            }
        )
    }

    onHandleCountry(e) {
        const value = e.target.value

        const isValid = COUNTRY.filter(it => it.key === value).length !== 0

        const region = isValid ? REGION.filter(it => this.state.region === it.key && value === it.country)[0] : "CHOOSE"

        this.setState(
            (state, _) => ( { country: value, region: region } ),
            () => {
                store.data.search.country = this.state.country
                store.data.search.region = this.state.region
                store.save()
            }
        )
    }

    onHandleRegion(e) {
        const value = e.target.value

        const country = REGION.filter(it => it.key === value)[0].country

        this.setState(
            (state, _) => ( { country: country, region: value } ),
            () => {
                store.data.search.region = this.state.region
                store.save()
            }
        )
    }

    onHandleCountryDelete(e) {
        this.setState(
            (state, _) => ( { country: "CHOOSE", region: "CHOOSE" } ),
            () => {
                store.data.search.country = "CHOOSE"
                store.data.search.region = "CHOOSE"
                store.save()
            }
        )
    }

    onHandleFieldDelete(e) {
        const id = e.target.id.split("-")[1]

        const value = id !== "region" ? "" : "CHOOSE"

        this.setState(
            (state, _) => ( { [id]: value } ),
            () => {
                store.data.search[id] = this.state[id]
                store.save()
            }
        )
    }

    handleGroundSelected(e) {
        this.setState(
            (state, _) => ({[e.target.id]: !state[e.target.id]}),
            () => {
                store.data.search.groundSelected[e.target.id] = this.state[e.target.id]
                store.save()
            }
        )
    }

    onHandleNavigate(e) {
        const id = e.target.id

        const path = "/" + id

        return this.navigate(path)
    }

    async onHandleNavigateAccount() {
        const res = await userService.getById(this.props.context.user.userId)

        if (res.status === 401) return disconnect(this.navigate, this.props.context, -1)

        if (this.props.location.pathname === "/my-settings") return

        const isOnUserSettings = this.props.location.pathname === "/user-settings"

        if (isOnUserSettings) setTimeout(() => window.location.reload(), 500)

        this.navigate("/my-settings", { state: { user: res, needReload: isOnUserSettings } })
    }

    groundButton(
        name,
        color,
        label
    ) {
        const isSelected = this.state[ name ]

        if (isSelected) return <button
            id={ name }
            type="button"
            className={ "col-12 btn btn-sm shadow btn-" + color }
            onClick={ this.handleGroundSelected }
        >{ label }</button>

        return <button
            id={ name }
            type="button"
            className={ "col-12 btn btn-sm active" }
            onClick={ this.handleGroundSelected }
            style={ { height: "31px" } }
        >{ label }</button>
    }

    navLink() {
        const isConnected = this.isConnected()

        const isAdmin = isConnected && this.props.context.user.isAdmin

        return !isAdmin ? this.defaultNavLink(isConnected) : this.adminNavLink()
    }

    defaultNavLink(isConnected) {

        const submitTournamentId = isConnected ? "games" : "game-edition"
        const submitTournamentLabel = isConnected ? i18n.t("navBar.my-tournaments") : i18n.t("navBar.submitTournament")

        return <ul className="navbar-nav me-auto mb-lg-0">
            <li className="nav-item">
                <button
                    id="home"
                    className="nav-link"
                    onClick={ this.onHandleNavigate }
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >{ i18n.t("navBar.home") }</button>
            </li>
            <li className="nav-item">
                <button
                    id={ submitTournamentId }
                    onClick={ this.onHandleNavigate }
                    className="nav-link"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >{ submitTournamentLabel }</button>
            </li>
            <li className="nav-item">
                <button
                    id="contact"
                    className="nav-link"
                    onClick={ this.onHandleNavigate }
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >{ i18n.t("navBar.contact") }</button>
            </li>
        </ul>
    }

    adminNavLink() {
        return <ul className="navbar-nav me-auto mb-lg-0">
            <li className="nav-item">
                <button
                    id="home"
                    className="nav-link"
                    onClick={this.onHandleNavigate}
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >{ i18n.t("navBar.home") }</button>
            </li>
            <li className="nav-item dropdown">
                <a
                    className="nav-link dropdown-toggle"
                    href="#" role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >{ i18n.t("navBar.games") }</a>
                <ul className="dropdown-menu bg-body-tertiary">
                    <li className="nav-item">
                        <button
                            id="games"
                            onClick={ this.onHandleNavigate }
                            className="nav-link"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            style={ NESTED_NAV_LINK_STYLE }
                        >{ i18n.t("navBar.my-tournaments") }</button>
                    </li>
                    <li className="nav-item">
                        <button
                            id="games-all"
                            onClick={ this.onHandleNavigate }
                            className="nav-link"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            style={ NESTED_NAV_LINK_STYLE }
                        >{ i18n.t("navBar.all-tournaments") }</button>
                    </li>
                    <li className="nav-item">
                        <button
                            id="games-to-validate"
                            onClick={ this.onHandleNavigate }
                            className="nav-link"
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                            style={ NESTED_NAV_LINK_STYLE }
                        >{ i18n.t("navBar.gameToValidate") }</button>
                    </li>
                </ul>
            </li>
            <li className="nav-item">
                <button
                    id="users"
                    className="nav-link"
                    onClick={ this.onHandleNavigate }
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >{ i18n.t("navBar.users") }</button>
            </li>
            <li className="nav-item">
            <button
                    id="contact"
                    className="nav-link"
                    onClick={this.onHandleNavigate}
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                >{ i18n.t("navBar.contact") }</button>
            </li>
        </ul>
    }

    search() {
        searchByParams().then(({ games, records }) => {
            this.props.context.search.games = games
            this.props.context.search.records = records
            this.props.context.search.requestTime = moment().toDate()

            const href = window.location.search
            const search = href.split("?")[1]

            const path = search != null && search !== "" ?  "/home?" + search : "/home"

            this.navigate(path)
        })
    }

    navBarTop() {
        if (window.mobileCheck) return "5px"

        return "20px"
    }

    navBarBottomPadding() {
        if (window.mobileCheck) return "11px"

        return "18px"
    }

    render() {
        return <div>
            <div className="row pb-3">
                <div
                    className="offcanvas offcanvas-start"
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                >
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">⚡ { i18n.t("sideBar.title") } ⚡</h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="offcanvas-body py-0">
                        <form className="col" role="search">
                            <div className="mb-3">
                                <div className="input-group shadow rounded col-12">
                                    <input
                                        className="form-control"
                                        type="text"
                                        placeholder={ i18n.t("sideBar.keyword") }
                                        value={ this.state.keyword }
                                        onChange={ this.onHandleKeyword }
                                    />
                                    <button
                                        id="deleteField-keyword"
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={ this.onHandleFieldDelete }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-x-lg"
                                            viewBox="0 0 16 16"
                                            style={ { pointerEvents: "none" } }
                                        >
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="input-group shadow rounded">
                                    <label className="input-group-text" htmlFor="inputGroupSelect01">
                                        { i18n.t("common.country") }
                                    </label>
                                    <select
                                        className="form-select"
                                        id="countryInputGroup"
                                        value={ this.state.country }
                                        onChange={ this.onHandleCountry }
                                    >
                                        { country() }
                                    </select>
                                    <button
                                        id="deleteField-country"
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={ this.onHandleCountryDelete }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-x-lg"
                                            viewBox="0 0 16 16"
                                            style={ { pointerEvents: "none" } }
                                        >
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="input-group shadow rounded">
                                    <label className="input-group-text" htmlFor="inputGroupSelect01">
                                        { i18n.t("sideBar.region.name") }
                                    </label>
                                    <select
                                        className="form-select"
                                        id="regionInputGroup"
                                        value={ this.state.region }
                                        onChange={ this.onHandleRegion }
                                    >
                                        { regionByCountry(this.state.country) }
                                    </select>
                                    <button
                                        id="deleteField-region"
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        onClick={ this.onHandleFieldDelete }
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-x-lg"
                                            viewBox="0 0 16 16"
                                            style={ { pointerEvents: "none" } }
                                        >
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-3 mb-3">
                                <div className="col-12">
                                    <DateRangePicker
                                        startDatePlaceholderText={ i18n.t("sideBar.date.startDate") }
                                        endDatePlaceholderText={ i18n.t("sideBar.date.endDate") }
                                        startDate={ this.state.startDate } // momentPropTypes.momentObj or null,
                                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                        endDate={ this.state.endDate } // momentPropTypes.momentObj or null,
                                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                        onDatesChange={ this.dateOnDatesChange() } // PropTypes.func.isRequired,
                                        focusedInput={ this.state.focusedInput } // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={ this.dateOnFocusChange() } // PropTypes.func.isRequired,
                                        orientation="vertical"
                                        verticalHeight={ 568 }
                                        // showDefaultInputIcon={true}
                                        block={ true }
                                        isOutsideRange={ day => (day < moment()) }
                                        // isOutsideRange={ () => false }
                                        daySize={ this.datePickerWidth() }
                                        showClearDates={ true }
                                        displayFormat={ DATE_FORMAT }
                                        firstDayOfWeek={ 1 }
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <Level
                                    min={ this.state.level.min }
                                    max={ this.state.level.max }
                                    padding="p-1"
                                    onHandleLevel={ this.onHandleLevel }
                                />
                            </div>
                            <div className="mb-2">
                                <div className="col-12">
                                    <div className="row mb-2">
                                        <div className="col-12">
                                            { this.groundButton("indoor", "bd-custom", "INDOOR") }
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-6 pe-1">
                                            { this.groundButton("beach", "warning", "BEACH") }
                                        </div>
                                        <div className="col-6 ps-1">
                                            { this.groundButton("green", "success", "GREEN") }
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 pe-1">
                                            { this.groundButton("water", "primary", "WATER") }
                                        </div>
                                        <div className="col-6 ps-1">
                                            { this.groundButton("snow", "info", "SNOW") }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="button"
                                data-bs-dismiss="offcanvas"
                                onClick={ this.search }
                                className="btn btn-primary btn-sm shadow col-12"
                            >
                                { i18n.t("sideBar.search") }
                            </button>
                        </form>
                    </div>
                    <div className="col-12 mb-3 text-center">
                        <button
                            id="settings"
                            className="btn btn-secondary btn-sm shadow col-11"
                            data-bs-dismiss="offcanvas"
                            onClick={ this.onHandleNavigate }
                        >{ i18n.t("settings.name") }</button>
                    </div>
                </div>
            </div>

            <div
                className="bg-body position-fixed"
                style={ { top: 0, left: 0, right: 0, height: this.navBarTop(), zIndex: "1030" } }
            ></div>

            <div
                className="bg-transparent rounded-4 position-fixed"
                style={ { top: this.navBarTop(), left: 0, right: 0, zIndex: "1030" } }
            >
                <div className="container">
                    <div className="bg-body rounded-bottom-4 col-lg-9 col-md-10 col-sm-12 mx-auto">
                        <nav className="navbar navbar-expand-md bg-body-secondary border border-1 border-body mx-auto rounded-4 shadow">
                            <div className="container-fluid">
                                <div className="navbar-brand p-1">
                                    <button
                                        type="button"
                                        className="btn btn-navbar border-0 p-0"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#offcanvasExample"
                                        aria-controls="offcanvasExample"
                                    >
                                        <img src={ logo } alt="logo" style={ { height: "40px" } }/>
                                    </button>
                                </div>
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse fw-medium" id="navbarSupportedContent">
                                    { this.navLink() }
                                    <div>{ this.button() }</div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div style={ { paddingTop: this.navBarBottomPadding(), paddingBottom: this.navBarBottomPadding() } }></div>
        </div>
    }
}