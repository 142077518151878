import React, { Component } from "react"
import { capitalizeFirstLetter } from "../const/common"
import i18n from "i18next"

export class SessionCard extends Component {

    render() {
        return <div className="col-12 bg-body-tertiary rounded shadow p-4">
            <div className="row row-gap-3">
                <div className="d-flex justify-content-between">
                    <div>{ i18n.t("user-settings.connexion-card.date-of-connexion") }:</div>
                    <div className="fst-italic">{ capitalizeFirstLetter(this.props.createdDate) }</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>{ i18n.t("user-settings.connexion-card.browser") }:</div>
                    <div className="fst-italic">{ this.props.browser }</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>{ i18n.t("user-settings.connexion-card.device") }:</div>
                    <div className="fst-italic">{ this.props.deviceType } - { this.props.operatingSystem }</div>
                </div>
                <div className="d-flex justify-content-between">
                    <div>{ i18n.t("user-settings.connexion-card.authentication-successful") }:</div>
                    <div className="fst-italic">{ this.props.isValidPassword ? i18n.t("common.yes") : i18n.t("common.no") }</div>
                </div>
            </div>
        </div>
    }
}