import { useLocation, useNavigate } from "react-router-dom"
import React, { useContext } from "react"
import { Context } from "../const/context"
import { isNotConnected } from "../const/utils"
import Login from "./login"
import i18n from "i18next"
import moment from "moment"
import { SessionCard } from "../component/session-card"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    if (isNotConnected(context)) return <Login/>

    const state = location.state

    const isNotEmptyState = state != null

    const sessions = state.sessions

    if (!isNotEmptyState || sessions == null) return navigate(-1)

    return <Sessions { ...props } navigate={ navigate } location={ location } context={ context } sessions={ sessions }/>
}

class Sessions extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = props.navigate

        this.sessions = props.sessions
    }

    sessionsCard() {
        return this.sessions.map(it => {
            const createdDate = moment(it.createdDate).locale(i18n.language).format('Do MMMM YYYY, HH:mm')
            const isValidPassword = it.isValidPassword
            const browser = it.connection.browser
            const system = it.connection.system

            return <React.Fragment key={ it.id }>
                <SessionCard
                    createdDate={ createdDate }
                    browser={ browser.browser }
                    deviceType={ system.deviceType }
                    operatingSystem={ system.operatingSystem }
                    isValidPassword={ isValidPassword }
                />
            </React.Fragment>
        })
    }

    render() {
        return <div className="col-lg-9 col-md-10 col-sm-12 mx-auto pb-3">

            <div className="row shadow bg-body-tertiary rounded px-3 py-2 mx-0 mb-3">

                <div className="col-7 col-md-9 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("sessions.title") }</p>
                </div>

                <div className="col-5 col-md-3 text-end m-auto px-0">
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn btn-warning shadow"
                            onClick={ () => {
                                this.navigate(-1)
                            } }
                        >
                            <div className="row">
                                <div className="col-1">

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17" height="17"
                                        fill="currentColor"
                                        className="bi bi-arrow-left-circle"
                                        viewBox="-2 0 20 15"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                        />
                                    </svg>
                                </div>
                                <div className="col px-1">{ i18n.t("button.back") }</div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="h4 pb-2 border-bottom border-2 border-theme-color mb-4 shadow"></div>
            </div>

            <div className="row row-gap-3 mx-0 px-0">{ this.sessionsCard() }</div>
        </div>
    }
}