import i18n from 'i18next'

import React from "react"
import { useNavigate } from "react-router-dom"
import { store } from "../repository/store"
import { handleMachineTheme } from "../const/utils"

export default function(props) {
    const navigate = useNavigate()

    return <Settings { ...props } navigate={ navigate } />
}

class Settings extends React.Component {

    constructor(props) {
        super(props)

        const user = store.data.user
        const theme = user.theme
        const language = user.language

        this.navigate = this.props.navigate

        this.state = {
            theme: theme,
            language: language
        }

        this.onHandleTheme = this.onHandleTheme.bind(this)
        this.onHandleLanguage = this.onHandleLanguage.bind(this)
        this.onHandleSave = this.onHandleSave.bind(this)
    }

    onHandleTheme(e) {
        const value = e.target.id

        this.setState(() => ( { theme: value } ) )
    }

    onHandleLanguage(e) {
        const value = e.target.value

        this.setState((state, _) => ( { language: value } ) )
    }

    async onHandleSave() {
        const theme = this.state.theme === "auto" ? handleMachineTheme() : this.state.theme
        const language = this.state.language

        document.getElementById("body").setAttribute("data-bs-theme", theme)
        await i18n.changeLanguage(language)

        store.data.user.theme = this.state.theme
        store.data.user.language = language
        store.save()

        this.navigate(-1)
    }

    render() {
        const saveIcon = <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17" height="17"
            fill="currentColor"
            className="bi bi-floppy2"
            viewBox="0 0 20 15">
            <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>
        </svg>

        return <div className="col-lg-9 col-md-10 col-sm-12 mx-auto">

            <div className="row shadow bg-body-tertiary rounded px-3 py-2 mx-0 mb-3">
                <div className="col-7 col-md-9 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("settings.name") }</p>
                </div>
                <div className="col-5 col-md-3 text-end m-auto px-0">
                    <div className="d-flex justify-content-end">
                        <div className="fancy">
                            <button
                                className="btn btn-primary"
                                onClick={ this.onHandleSave }
                            >
                                <div className="row">
                                    <div className="col-1">
                                        { saveIcon }
                                    </div>
                                    <div className="col px-1">{ i18n.t("button.save") }</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="h4 pb-2 border-bottom border-2 border-theme-color mb-4 shadow"></div>
            </div>

            <div className="row row-gap-4 bg-body-tertiary shadow rounded fw-medium p-4 mx-0 mb-4">
                <div className="col-12 p-0">
                    <div className="ps-1 pb-3">Theme</div>
                    <div
                        className="btn-group btn-group-sm col-12"
                        role="group"
                        aria-label="Basic radio toggle button group"
                    >
                        <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="auto"
                            autoComplete="off"
                            onChange={ this.onHandleTheme }
                            checked={ this.state.theme === "auto" }
                        />
                        <label className="btn btn-outline-primary" htmlFor="auto">
                            { i18n.t("settings.theme.auto") }
                        </label>

                        <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="light"
                            autoComplete="off"
                            onChange={ this.onHandleTheme }
                            checked={ this.state.theme === "light" }
                        />
                        <label className="btn btn-outline-primary" htmlFor="light">
                            { i18n.t("settings.theme.light") }
                        </label>

                        <input
                            type="radio"
                            className="btn-check"
                            name="btnradio"
                            id="dark"
                            autoComplete="off"
                            onChange={ this.onHandleTheme }
                            checked={ this.state.theme === "dark" }
                        />
                        <label className="btn btn-outline-primary" htmlFor="dark">
                            { i18n.t("settings.theme.dark") }
                        </label>
                    </div>
                </div>

                <div className="col-12 p-0">
                    <div className="ps-1 pb-3">{ i18n.t("settings.language.name") }</div>
                    <div>
                        <select
                            className="form-select form-select-sm"
                            aria-label="Language selector"
                            value={ this.state.language }
                            onChange={ this.onHandleLanguage }
                        >
                            <option key="en" value="en">{ i18n.t("settings.language.english") }</option>
                            <option key="fr" value="fr" defaultValue>{ i18n.t("settings.language.french") }</option>
                        </select>
                    </div>
                </div>

            </div>
        </div>
    }
}