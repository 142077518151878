import i18n from 'i18next'
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { userService } from "../service/user-service"
import { Error } from "./error"
import { store } from "../repository/store"
import { Captcha } from "../component/captcha"
import { infoToast } from "../const/utils"

export default function(props) {
    const navigate = useNavigate()
    const { state } = useLocation()

    if (state == null) return <Error/>

    const identifier = state.identifier
    const reason = state.reason

    if (identifier == null) return <Error/>
    if (reason == null) return <Error/>

    return <Code
        { ...props }
        navigate={ navigate }
        identifier={ identifier }
        reason={ reason }
    />
}

class Code extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            code: "",
            sendCodeReCaptchaToken: null,
            newCodeReCaptchaToken: null,
        }

        this.navigate = this.props.navigate

        this.handleCode = this.handleCode.bind(this)

        this.onHandleSendCodeReCaptchaToken = this.onHandleSendCodeReCaptchaToken.bind(this)
        this.onHandleNewCodeReCaptchaToken = this.onHandleNewCodeReCaptchaToken.bind(this)

        this.button = this.button.bind(this)
        this.disabledButton = this.disabledButton.bind(this)
        this.enabledButton = this.enabledButton.bind(this)

        this.newCode = this.newCode.bind(this)
        this.sendCode = this.sendCode.bind(this)

        this.sleep = this.sleep.bind(this)
    }

    handleCode(e) {
        const newCode = e.target.value.trim()

        this.setState((state, _) => ({ code: newCode }))
    }

    onHandleSendCodeReCaptchaToken(reCaptchaToken) {
        this.setState(({ sendCodeReCaptchaToken: reCaptchaToken }))
    }

    onHandleNewCodeReCaptchaToken(reCaptchaToken) {
        this.setState(({ newCodeReCaptchaToken: reCaptchaToken }))
    }

    button() {
        const isValid = this.state.code !== ""

        if (!isValid) return this.disabledButton()
        else return this.enabledButton()
    }

    disabledButton() {
        return <button className="w-100 btn btn-lg btn-primary" disabled>
            { i18n.t("code.send") }
        </button>
    }

    enabledButton() {
        return <button className="w-100 btn btn-lg btn-primary" onClick={ this.sendCode }>
            { i18n.t("code.send") }
        </button>
    }

    async sendCode() {

        const token = this.state.sendCodeReCaptchaToken

        if (token == null) {
            const myModal = new bootstrap.Modal(document.getElementById("sendCodeReCaptcha"), {})
            myModal.show()
            return
        }

        const identifier = this.props.identifier

        const data = await userService.updateCode(identifier, this.state.code, this.props.reason, token)

        const anyError = data.error || data.httpCode != null

        if (anyError) this.setState(({ sendCodeReCaptchaToken: null }))
        else if (data.httpCode == null) {
            infoToast(i18n.t("toast.success.user-created"))

            store.data.user.identifier = ""
            store.data.user.password = ""
            store.data.user.remember = false
            store.save()

            this.sleep(2000).then(() => this.navigate("/login"))
        }
    }

    async newCode() {

        const token = this.state.newCodeReCaptchaToken

        if (token == null) {
            const myModal = new bootstrap.Modal(document.getElementById("newCodeReCaptcha"), {})
            myModal.show()
            return
        }

        const data = await userService.createCode(this.props.identifier, this.props.reason, token)

        this.setState(
            ({ newCodeReCaptchaToken: null }),
            () => {
                if (data.httpCode == null) infoToast(i18n.t("toast.success.new-code-generated"))
            }
        )
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render() {
        return <div>
            <div className="col">
                <div className="row pb-3">
                    <div id="login" className="d-flex justify-content-center text-center">
                        <main className="form-signin w-100">
                            <div className="row row-gap-3">
                                <div className="p-0">
                                    <h1 className="h3 mb-3 fw-normal">{ i18n.t("code.title") }</h1>
                                    <div className="form-floating">
                                        <input
                                            type="email"
                                            className="form-control rounded rounded-bottom-0"
                                            id="floatingInput"
                                            placeholder="295fc678-7f8e-4509-80f2-1aedaf246c6e"
                                            disabled={ true }
                                        />
                                        <label htmlFor="floatingInput">
                                            { this.props.identifier }
                                        </label>
                                    </div>
                                    <div className="form-floating">
                                        <input
                                            type="email"
                                            className="form-control rounded rounded-top-0"
                                            id="floatingInput"
                                            placeholder="295fc678-7f8e-4509-80f2-1aedaf246c6e"
                                            value={ this.state.code }
                                            onChange={ this.handleCode }
                                        />
                                        <label htmlFor="floatingInput">{ i18n.t("code.code") }</label>
                                    </div>
                                </div>
                                { this.button() }
                                <button
                                    className="w-100 btn btn-lg btn-warning mt-1"
                                    onClick={ this.newCode }
                                >
                                    { i18n.t("code.newCode") }
                                </button>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
            <Captcha
                reCaptchaModalId="sendCodeReCaptcha"
                onHandleToken={ this.onHandleSendCodeReCaptchaToken }
                onClick={ this.sendCode }
            />
            <Captcha
                reCaptchaModalId="newCodeReCaptcha"
                onHandleToken={ this.onHandleNewCodeReCaptchaToken }
                onClick={ this.newCode }
            />
        </div>
    }
}