import i18n from 'i18next'
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Error } from "./error"
import { store } from "../repository/store"
import { MAIL_VERIFICATION, PASSWORD_TO_RESET } from "../const/reason"
import { userService } from "../service/user-service"
import { Captcha } from "../component/captcha"
import { infoToast } from "../const/utils"

export default function(props) {
    const navigate = useNavigate()
    const { state } = useLocation()

    if (state == null) return <Error/>

    const reason = state.reason

    return <Identifier { ...props } navigate={ navigate } reason={ reason }/>
}

class Identifier extends React.Component {

    constructor(props) {
        super(props)

        const value = store.data.user.identifier
        const isValid = value != null && value !== ""

        this.timeoutId = ""

        this.state = {
            identifier: {
                value: value,
                isValid: isValid,
                disabled: false
            },
            button: {
                disabled: false
            },
            reCaptchaToken: null,
        }

        this.navigate = this.props.navigate

        this.handleIdentifier = this.handleIdentifier.bind(this)
        this.onHandleReCaptchaToken = this.onHandleReCaptchaToken.bind(this)

        this.button = this.button.bind(this)
        this.disabledButton = this.disabledButton.bind(this)
        this.enabledButton = this.enabledButton.bind(this)

        this.next = this.next.bind(this)
        this.toPasswordReset = this.toPasswordReset.bind(this)
        this.toMailVerification = this.toMailVerification.bind(this)
    }

    handleIdentifier(e) {
        const value = e.target.value.trim()
        const isValid = value != null && value !== ""

        const id = { value: value, isValid: isValid }

        this.setState((state, _) => ({ identifier: id }))
    }

    onHandleReCaptchaToken(reCaptchaToken) {
        this.setState(({ reCaptchaToken: reCaptchaToken }))
    }

    button() {
        const isValid = this.state.identifier.isValid

        if (!isValid) return this.disabledButton()
        else return this.enabledButton()
    }

    disabledButton() {
        return <button className="btn btn-lg btn-primary" disabled>{ i18n.t("identifier.next") }</button>
    }

    enabledButton() {
        return <button className="btn btn-lg btn-primary shadow" onClick={ this.next }>{ i18n.t("identifier.next") }</button>
    }

    async next() {
        const value = this.state.identifier.value
        const isValid = this.state.identifier.isValid

        if (!isValid) return

        const reCaptchaToken = this.state.reCaptchaToken

        if (reCaptchaToken == null) {
            const myModal = new bootstrap.Modal(document.getElementById("raCaptachModal"), {})
            myModal.show()
            return
        }

        if (this.props.reason === PASSWORD_TO_RESET) await this.toPasswordReset(value, reCaptchaToken)
        if (this.props.reason === MAIL_VERIFICATION) await this.toMailVerification(value, reCaptchaToken)
    }

    async toPasswordReset(
        identifier,
        reCaptchaToken
    ) {
        const data = await userService.createCode(identifier, PASSWORD_TO_RESET, reCaptchaToken)

        const anyError = data.error != null || data.httpCode != null
        const isInvalidUser = anyError && (data.code != null && data.code.value === "ENTITY_NOT_FOUND")

        if (!anyError) {
            const callback = () => { this.navigate("/password", { state: { identifier } }) }
            infoToast(i18n.t("toast.success.new-code-generated"))
            setTimeout(callback, 2000)
        } else if (isInvalidUser || data.code != null) this.setState(({ reCaptchaToken: null }))
    }

    async toMailVerification(
        identifier,
        reCaptchaToken
    ) {
        const data = await userService.createCode(identifier, MAIL_VERIFICATION, reCaptchaToken)

        const anyError = data.error != null || data.httpCode != null
        const isUserAlreadyValidated = anyError && (data.code != null && data.code.value === "USER_VERIFIED")
        const isInvalidUser = anyError && (data.code != null && data.code.value === "ENTITY_NOT_FOUND")

        if (!anyError) {
            const callback = () => { this.navigate("/code", { state: { identifier, reason: this.props.reason } }) }
            infoToast(i18n.t("toast.success.new-code-generated"))
            setTimeout(callback, 2000)
        } else if (isUserAlreadyValidated) {
            const callback = () => { setTimeout(() => { this.navigate("/login") }, 2000) }
            this.setState(({ reCaptchaToken: null }), callback)
        } else if (isInvalidUser || data.code != null) {
            this.setState(({ reCaptchaToken: null }))
        }
    }

    render() {
        return <div>
            <div id="identifier" className="d-flex justify-content-center text-center">
                <main className="form-signin w-100">
                    <div className="row row-gap-3">
                        <div className="col-12 p-0">
                            <h1 className="h3 mb-3 fw-normal">{ i18n.t("identifier.title") }</h1>
                            <div className="form-floating shadow rounded">
                                <input
                                    type="email"
                                    className="form-control rounded"
                                    id="floatingInput"
                                    placeholder="Joy.boy@yopmail.com"
                                    value={ this.state.identifier.value }
                                    onChange={ this.handleIdentifier }
                                />
                                <label htmlFor="floatingInput">{ i18n.t("common.identifier") }</label>
                            </div>
                        </div>
                        { this.button() }
                    </div>
                </main>
            </div>
            <Captcha onHandleToken={ this.onHandleReCaptchaToken } onClick={ this.next }/>
        </div>

    }
}