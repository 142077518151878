import playerAttack from "../../public/player_attack.png"

export default function ShowcasePicture(props) {

    const anyPicture = props.url != null
    const url = anyPicture ? props.url : playerAttack
    const maxHeight = window.mobileCheck ? "300px" : "500px"
    const padding = props.padding != null ? props.padding : "0px"

    return <button
        type="button"
        className="btn p-0 border-0 col-12"
        data-bs-toggle="modal"
        data-bs-target="#gameModal"
        data-bs-whatever="@mdo"
        onClick={ () => props.onHandleModalUrl(url) }
    >
        <img
            src={ url }
            className="card-img-top"
            alt="tounament_image"
            style={ {
                objectFit: "cover",
                objectPosition: "top",
                maxHeight: maxHeight,
                borderRadius: "0.375rem",
                padding: padding
            } }
        />
    </button>
}