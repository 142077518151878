import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import i18n from "i18next"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()

    const state = location.state

    const isNotEmptyState = state != null

    const message = isNotEmptyState ? state.message : i18n.t("success.default-message")

    return <Success { ...props } navigate={ navigate } message={ message } />
}

class Success extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = this.props.navigate
    }

    componentDidMount() {
        setTimeout(() => { this.navigate("/") }, 10000)
    }

    render() {
        return <div className="col-lg-9 col-md-10 col-sm-12 mx-auto p-0">
            <div className="row shadow bg-body-tertiary rounded mx-0 text-center p-4">
                <div className="col-12 px-0 m-auto m-4">
                    <h1 className="align-middle">🎊 { i18n.t("success.title") } 🎊</h1>
                    <div className="my-2">...</div>
                    <div className="lead fw-semibold align-bottom">
                        { this.props.message }
                    </div>
                </div>
            </div>
        </div>
    }
}