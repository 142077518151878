import '../css/hello-assos.scss'
import helloassologo from "../../public/hello_asso_logo.svg"

import React, { useContext } from 'react';
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from "../const/context"
import { disconnect, isNotConnected, notEmptyFieldValidator } from "../const/utils"
import Login from "./login"
import i18n from "i18next"
import { Input } from "../component/input"
import { userService } from "../service/user-service"
import moment from "moment/moment"
import { DATE_FORMAT } from "../const/common"
import { sessionService } from "../service/session-service"
import { SessionCard } from "../component/session-card"

export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    if (isNotConnected(context)) return <Login/>

    const state = location.state

    const isNotEmptyState = state != null

    const user = isNotEmptyState ? state.user : null

    const needReload = isNotEmptyState && state.needReload !== undefined ? state.needReload : false

    if (!isNotEmptyState || user == null) return navigate(-1)

    return <UserSettings
        { ...props }
        navigate={ navigate }
        location={ location }
        context={ context }
        user={ user }
        needReload={ needReload }
    />
}

class UserSettings extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = props.navigate

        this.currentUser = props.user
        this.needReload = props.needReload

        this.state = {
            isEnableSaveButton: true,

            username: props.user.username,
            firstname: props.user.firstName,
            lastname: props.user.lastName,
            bornDate: { isValid: true, value: props.user.bornDate },
            gender: props.user.gender,
            sessions: [],
            authorization: []
        }

        this.onHandleTextInput = this.onHandleTextInput.bind(this)
        this.onHandleGender = this.onHandleGender.bind(this)
        this.onDateChange = this.onDateChange.bind(this)

        this.onHandleSessions = this.onHandleSessions.bind(this)
        this.onHandleSave = this.onHandleSave.bind(this)
    }

    componentDidMount() {
        sessionService.getAll(
            this.currentUser.id
        ).then(
            (res) => {
                if (res.status === 401) return disconnect(this.navigate, this.props.context, "/user-settings")
                if (res.status === 403) return this.navigate("/home")

                this.setState((state, _) => ( { sessions: res } ))
            }
        )

        // notifierService.addEventListener("message", evt => {
        //     const body = JSON.parse(evt.data)
        // })
    }

    componentWillUnmount() {
        // notifierService.removeEventListener("message")
    }

    isValid(key) {
        if (this.state[key].isValid) return ""
        else return "is-invalid"
    }

    onHandleTextInput(id, value) {
        this.setState(
            (state, _) => ( { [id]: value } ),
            () => {
                const username = this.state.username
                const firstname = this.state.firstname
                const lastname = this.state.lastname

                const isEnableSaveButton = this.state.bornDate.isValid && notEmptyFieldValidator(username) && notEmptyFieldValidator(firstname) && notEmptyFieldValidator(lastname)

                this.setState((state, _) => ( { isEnableSaveButton: isEnableSaveButton } ))
            }
        )
    }

    onHandleGender(e) {
        const newState = { gender: e.target.id }

        this.setState((state, _) => ( newState ))
    }

    onDateChange(e) {
        const date = e.target.value

        const formattedDate = moment(date, DATE_FORMAT)
        const years = new Date().getFullYear() - 18;

        const isValidDate = formattedDate.format(DATE_FORMAT) === date && formattedDate <= ( new Date().setFullYear(years) )

        const newState = { bornDate: { isValid: isValidDate, value: date } }

        const username = this.state.username
        const firstname = this.state.firstname
        const lastname = this.state.lastname

        const isEnableSaveButton =  isValidDate && notEmptyFieldValidator(username) && notEmptyFieldValidator(firstname) && notEmptyFieldValidator(lastname)

        this.setState(
            (state, _) => ( newState ),
            () => this.setState((state, _) => ( { isEnableSaveButton: isEnableSaveButton } ))
        )
    }

    lastSession() {

        const sessions = this.state.sessions

        const lastSession = sessions[0]

        if (lastSession == null) return <React.Fragment></React.Fragment>

        const createdDate = moment(lastSession.createdDate).locale(i18n.language).format('Do MMMM YYYY, HH:mm')
        const isValidPassword = lastSession.isValidPassword
        const browser = lastSession.connection.browser
        const system = lastSession.connection.system

        return <SessionCard
            createdDate={ createdDate }
            browser={ browser.browser }
            deviceType={ system.deviceType }
            operatingSystem={ system.operatingSystem }
            isValidPassword={ isValidPassword }
        />
    }

    onHandleSessions() {
        this.props.navigate("/sessions", { state: { sessions: this.state.sessions } })
    }

    back() {
        return () => {
            if (this.needReload) setTimeout(() => window.location.reload(), 500)
            this.navigate(-1)
        }
    }

    saveButton() {
        const saveIcon = <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17" height="17"
            fill="currentColor"
            className="bi bi-floppy2"
            viewBox="0 0 20 15">
            <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v3.5A1.5 1.5 0 0 1 11.5 6h-7A1.5 1.5 0 0 1 3 4.5V1H1.5a.5.5 0 0 0-.5.5m9.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"/>
        </svg>

        if (!this.state.isEnableSaveButton) return <button
            type="button"
            className="btn btn-secondary disabled-fancy"
            disabled
        >
            <div className="row">
                <div className="col-1">
                    { saveIcon }
                </div>
                <div className="col px-1">{ i18n.t("button.save") }</div>
            </div>
        </button>

        return <div className="d-flex justify-content-end">
            <div className="fancy">
                <button
                    className="btn btn-primary col-12"
                    onClick={ this.onHandleSave }
                >
                    <div className="row">
                        <div className="col-1">
                            { saveIcon }
                        </div>
                        <div className="col px-1">{ i18n.t("button.save") }</div>
                    </div>
                </button>
            </div>
        </div>
    }

    async onHandleSave() {

        const userId = this.currentUser.id
        const username = this.state.username
        const firstname = this.state.firstname
        const lastname = this.state.lastName
        const gender = this.state.gender
        const bornDate = this.state.bornDate.value

        const res = await userService.update(userId, username, firstname, lastname, gender, bornDate)

        if (res.status === 401) return disconnect(this.navigate, this.props.context, "/user-settings")

        const anyError = res.error != null || res.httpCode != null

        if (anyError) return

        if (this.needReload) setTimeout(() => window.location.reload(), 500)

        this.navigate(-1)
    }

    render() {
        return <div className="col-lg-9 col-md-10 col-sm-12 mx-auto pb-3">

            <div className="row shadow bg-body-tertiary rounded px-3 py-2 mx-0 mb-3">

                <div className="col-6 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("user-settings.title") }</p>
                </div>

                <div className="col-6 text-end m-auto px-0">
                    <div className="d-flex justify-content-end">
                        <div className="d-flex justify-content-between">
                            <button
                                className="btn btn-warning border border-4 border-black rounded-3 shadow p-1 me-1"
                                onClick={ this.back() }
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17" height="17"
                                    fill="currentColor"
                                    className="bi bi-arrow-left-circle"
                                    viewBox="-2 0 20 15"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                    />
                                </svg>
                            </button>
                            { this.saveButton() }
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="h4 pb-2 border-bottom border-2 border-theme-color mb-4 shadow"></div>
            </div>

            <div className="row row-gap-3 px-0 mx-0">
                <Input
                    id="username"
                    type="text"
                    iconName="username"
                    placeholder={ i18n.t("common.username") }
                    value={ this.state.username }
                    validator={ notEmptyFieldValidator }
                    onHandleInput={ this.onHandleTextInput }
                />
                <Input
                    id="firstname"
                    type="text"
                    iconName="firstname"
                    placeholder={ i18n.t("common.firstname") }
                    value={ this.state.firstname }
                    validator={ notEmptyFieldValidator }
                    onHandleInput={ this.onHandleTextInput }
                />
                <Input
                    id="lastname"
                    type="text"
                    iconName="lastname"
                    placeholder={ i18n.t("common.lastname") }
                    value={ this.state.lastname }
                    validator={ notEmptyFieldValidator }
                    onHandleInput={ this.onHandleTextInput }
                />
                <div className="btn-group col-12 p-0" role="group" aria-label="Select gender">
                    <input
                        id="MALE"
                        type="radio"
                        className="btn-check"
                        name="btnRadioGender"
                        autoComplete="off"
                        checked={ this.state.gender === "MALE" }
                        onChange={ this.onHandleGender }
                    />
                    <label className="btn btn-outline-primary col-4" htmlFor="MALE">{ i18n.t("common.male") }</label>
                    <input
                        id="UNKNOWN"
                        type="radio"
                        className="btn-check"
                        name="btnRadioGender"
                        autoComplete="off"
                        checked={ this.state.gender === "UNKNOWN" }
                        onChange={ this.onHandleGender }
                    />
                    <label className="btn btn-outline-primary col-4"
                           htmlFor="UNKNOWN">{ i18n.t("common.other") }</label>
                    <input
                        id="FEMALE"
                        type="radio"
                        className="btn-check"
                        name="btnRadioGender"
                        autoComplete="off"
                        checked={ this.state.gender === "FEMALE" }
                        onChange={ this.onHandleGender }
                    />
                    <label className="btn btn-outline-primary col-4"
                           htmlFor="FEMALE">{ i18n.t("common.female") }</label>
                </div>
                <div className="form-floating col-12 p-0">
                    <input
                        type="text"
                        className={ "form-control shadow " + this.isValid("bornDate") }
                        id="bornDate"
                        placeholder="bornDate"
                        value={ this.state.bornDate.value }
                        onChange={ this.onDateChange }
                    />
                    <label htmlFor="lastName">{ i18n.t("registry.bornDateInput") }</label>
                </div>
            </div>

            <div className="container" hidden={ this.state.sessions.length === 0 }>
                <div className="row mt-3 mb-3 mx-1">
                    <div className="col h4 border-bottom border-2 border-theme-color"></div>

                    <div className="text-center fw-semibold" style={ { maxWidth: "fit-content" } }>
                        { i18n.t("user-settings.past-connexion") }
                    </div>

                    <div className="col h4 border-bottom border-2 border-theme-color"></div>
                </div>
            </div>

            <div className="row row-gap-3 px-0" hidden={ this.state.sessions.length === 0 }>
                <div className="row row-gap-3 p-0 mx-0">

                    <div className="col-lg-11 col-md-11">{ this.lastSession() }</div>

                    <div className="col-lg-1 col-md-1 col-12">
                        <button
                            className="btn btn-secondary w-100 h-100 px-0 shadow"
                            onClick={ this.onHandleSessions }
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-right-circle"
                                viewBox="0 0 16 16"
                            >
                                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className="container" hidden={ this.state.authorization.length === 0 }>
                <div className="row mt-3 mb-3 mx-1">
                    <div className="col h4 border-bottom border-2 border-theme-color"></div>

                    <div className="text-center fw-semibold" style={ { maxWidth: "fit-content" } }>
                        { i18n.t("user-settings.authorization") }
                    </div>

                    <div className="col h4 border-bottom border-2 border-theme-color"></div>
                </div>
            </div>

            <div className="row row-gap-3 px-0" hidden={ this.state.authorization.length === 0 }>
                <div className="row row-gap-3 p-0 mx-0">
                    <div className="col-12">
                        <div className="col-12 bg-body-tertiary rounded rounded-bottom-0 shadow p-4">
                            <div className="row row-gap-3">
                                <div>
                                    Permission: Authentification
                                </div>
                                <div>
                                    Client: <img
                                    src={ helloassologo } alt="hello_asso"
                                    className="HaAuthorizeButtonLogo"
                                    style={ { width: '46px', verticalAlign: "text-bottom" } }
                                />HelloAsso
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-danger col-12 rounded-top-0">{ i18n.t("button.delete") }</button>
                    </div>
                </div>
            </div>

        </div>
    }
}