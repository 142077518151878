import React from "react"
import { useState, useLayoutEffect, useRef } from "react"
import { capitalizeFirstLetter, FLAG_BY_COUNTRY, regionFinder, regionTranslation } from "../const/common"
import { startDate } from "../const/utils"
import i18n from "i18next"
import ShowcasePicture from "./showcase-picture"

function countryByRegion(value) {
    return FLAG_BY_COUNTRY[value.country]
}

function buttonBy(ground) {
    switch (ground) {
        case "INDOOR": return "btn-bd-custom"
        case "GREEN": return "btn-success"
        case "BEACH": return "btn-warning"
        case "WATER": return "btn-primary"
        case "SNOW": return "btn-info"
    }
}

function gender(game, onHandleOffCanvasGame) {

    const nbOfPlayer = game.nbOfPlayer

    const minMaleInTeam = game.minMaleInTeam
    const minFemaleInTeam = game.minFemaleInTeam

    if (minMaleInTeam === nbOfPlayer) return <button
        className="btn btn-sm btn-dark"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvas"
        onClick={ () => onHandleOffCanvasGame(game) }
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-gender-male"
            viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M9.5 2a.5.5 0 0 1 0-1h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V2.707L9.871 6.836a5 5 0 1 1-.707-.707L13.293 2zM6 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8"
            />
        </svg>
    </button>

    if (minFemaleInTeam === nbOfPlayer) return <button
        className="btn btn-sm btn-dark"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvas"
        onClick={ () => onHandleOffCanvasGame(game) }
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-gender-female"
            viewBox="0 0 16 16"
        >
            <path
                fillRule="evenodd"
                d="M8 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8M3 5a5 5 0 1 1 5.5 4.975V12h2a.5.5 0 0 1 0 1h-2v2.5a.5.5 0 0 1-1 0V13h-2a.5.5 0 0 1 0-1h2V9.975A5 5 0 0 1 3 5"
            />
        </svg>
    </button>

    return <React.Fragment></React.Fragment>
}

function dateMaxWidthBy(game, refWith) {

    const nbOfPlayer = game.nbOfPlayer

    const minMaleInTeam = game.minMaleInTeam
    const minFemaleInTeam = game.minFemaleInTeam

    const isMixed = minMaleInTeam !== nbOfPlayer && minFemaleInTeam !== nbOfPlayer

    if (refWith <= 245) return refWith * (10 / 20)
    if (refWith <= 257) return refWith * (9.5 / 20)
    if (refWith <= 310) return refWith

    return refWith
}

export default function Showcase(props) {
    const game = props.game

    const ref = useRef(null)

    const [ titleMaxWidth, setTitleMaxWidth ] = useState(0)
    const [ regionMaxWidth, setRegionMaxWidth ] = useState(0)
    const [ dateMaxWith, setDateMaxWidth ] = useState(0)

    useLayoutEffect(() => {
        setTitleMaxWidth(ref.current.clientWidth < 315 ? ref.current.clientWidth * (3.9 / 8) : ref.current.clientWidth)
        setRegionMaxWidth(ref.current.clientWidth < 315 ? ref.current.clientWidth * (4.4 / 8) : ref.current.clientWidth)
        setDateMaxWidth(dateMaxWidthBy(game, ref.current.clientWidth))
    })

    const regionFromGame = regionFinder(game.region)

    const title = props.url == null ? <div className="col-12 mt-1">
        <button
            className="btn btn-sm btn-dark fw-semibold"
            style={
                {
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: titleMaxWidth,
                    width: "fit-content",
                    whiteSpace: "nowrap"
                }
            }
            data-bs-toggle="offcanvas"
            data-bs-target={ "#offcanvas" }
            onClick={ () => props.onHandleOffCanvasGame(game) }
        >{ game.name.toUpperCase() }</button>
    </div> : <React.Fragment></React.Fragment>

    const isMixed = game.nbOfPlayer !== game.minMaleInTeam && game.nbOfPlayer !== game.minFemaleInTeam
    const genderIcon = !isMixed ? gender(game, props.onHandleOffCanvasGame) : <React.Fragment></React.Fragment>

    const priceAndGender = game.price !== 0 ? <div className="d-flex mt-1">
        {  genderIcon }
        <div className="mx-1" hidden={ isMixed }></div>
        <button
            className="btn btn-sm btn-dark fw-semibold"
            style={ { width: "fit-content" } }
            data-bs-toggle="offcanvas"
            data-bs-target={ "#offcanvas" }
            onClick={ () => props.onHandleOffCanvasGame(game) }
        >
            { game.price } Є
        </button>
    </div> : <div className="d-flex mt-1">{ genderIcon }</div>

    return <div style={ { position: "relative", textAlign: "center", padding: "0" } } ref={ ref }>

        <ShowcasePicture url={ props.url } padding={ props.padding } onHandleModalUrl={ props.onHandleModalUrl }/>

        <div style={ { position: "absolute", top: "8px", left: "8px" } }>
            <button
                className={ "btn btn-sm fw-semibold " + buttonBy(game.ground) }
                style={ { width: "fit-content" } }
                data-bs-toggle="offcanvas"
                data-bs-target={ "#offcanvas" }
                onClick={ () => props.onHandleOffCanvasGame(game) }
            >
                { capitalizeFirstLetter(game.ground.toLowerCase()) }
            </button>
        </div>

        <div style={ { position: "absolute", top: "8px", right: "8px" } }>
            <div className="col text-end">
                <div className="col-12">
                    <button
                        className="btn btn-sm btn-dark fw-semibold text-end"
                        style={
                            {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: dateMaxWith,
                                whiteSpace: "nowrap",
                                width: "fit-content",
                            }
                        }
                        data-bs-toggle="offcanvas"
                        data-bs-target={ "#offcanvas" }
                        onClick={ () => props.onHandleOffCanvasGame(game) }
                    >{ startDate(game.startDate) }</button>
                </div>
            </div>
        </div>

        <div style={ { position: "absolute", top: "39px", right: "8px" } }>
            <div className="text-end">
                { priceAndGender }
            </div>
        </div>

        <div style={ { position: "absolute", bottom: "39px", left: "8px" } }>
            <div className="text-start">
                <div className="col-12 mb-1" style={ { width: "fit-content" } }>
                    <button
                        className="btn btn-sm btn-dark p-auto"
                        data-bs-toggle="offcanvas"
                        data-bs-target={ "#offcanvas" }
                        onClick={ () => props.onHandleOffCanvasGame(game) }
                    >
                        <img
                            src={ countryByRegion(regionFromGame) }
                            alt="flag"
                            width="15"
                            style={ { marginBottom: "2px" } }
                        />
                    </button>
                </div>
            </div>
        </div>

        <div style={ { position: "absolute", bottom: "8px", left: "8px" } }>
            <div className="text-start">
                <button
                    className="btn btn-sm btn-dark fw-semibold"
                    style={
                        {
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: regionMaxWidth,
                            width: "fit-content",
                            whiteSpace: "nowrap"
                        }
                    }
                    data-bs-toggle="offcanvas"
                    data-bs-target={ "#offcanvas" }
                    onClick={ () => props.onHandleOffCanvasGame(game) }
                >
                    { regionTranslation(regionFromGame) }
                </button>
                { title }
            </div>
        </div>

        <div className="text-end" style={ { position: "absolute", bottom: "8px", right: "8px" } }>
            <div className="col-12">
                <button
                    className="btn btn-sm btn-dark fw-semibold"
                    data-bs-toggle="offcanvas"
                    data-bs-target={ "#offcanvas" }
                    onClick={ () => props.onHandleOffCanvasGame(game) }
                >
                    { i18n.t("common.open") }
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right-square-fill ms-2"
                        viewBox="0 0 16 16"
                    >
                        <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
}
