import "dotenv/config"
import { APPLICATION_JSON, GET, POST, PUT } from "../const/request"
import { store } from "../repository/store"
import { resParser } from "../const/utils"

class UserService {

    async getAll(
        keyword
    ) {
        const formattedKeyword = keyword == null ? "" : keyword

        const auth = "Bearer " + store.data.token.access.value

        let path = process.env.URL + "/api/v1/users?page=0&size=100"

        if (keyword != null && keyword !== "") path = path + "&keyword=" + formattedKeyword

        const response = await fetch(path, {
            method: GET,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            }
        })

        return await resParser(response)
    }

    async getById(
        userId
    ){
        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.URL + "/api/v1/users/" + userId

        const response = await fetch(path, {
            method: GET,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            }
        })

        return await resParser(response)
    }

    async search(
        managerIds,
        managerNotIds,
        keyword
    ) {
        const formattedKeyword = keyword == null ? "" : keyword
        const formattedManagerIds = managerIds == null ? [] : managerIds
        const formattedManagerNotIds = managerNotIds == null ? [] : managerNotIds

        const auth = "Bearer " + store.data.token.access.value

        const rawBody = {
            ids: formattedManagerIds,
            nIds: formattedManagerNotIds,
            keyword: formattedKeyword
        }

        const body = JSON.stringify(rawBody)

        const path = process.env.URL + "/api/v1/users/search"

        const response = await fetch(path, {
            method: POST,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body,
        })

        return await resParser(response)
    }

    async create(
        username,
        password,
        mail,
        firstName,
        lastName,
        gender,
        bornDate,
        reCaptchaToken
    ) {
        const body = JSON.stringify({
            username: username,
            password: password,
            mail: mail,
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            bornDate: bornDate,
            reCaptchaToken: reCaptchaToken
        })

        const path = process.env.URL + "/api/v1/users"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body,
        })

        return await resParser(response)
    }

    async update(
        userId,
        username,
        firstname,
        lastname,
        gender,
        bornDate
    ){
        const auth = "Bearer " + store.data.token.access.value

        const body = JSON.stringify({
            username: username,
            firstName: firstname,
            lastName: lastname,
            gender: gender,
            bornDate: bornDate
        })

        const path = process.env.URL + "/api/v1/users/" + userId

        const response = await fetch(path, {
            method: PUT,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body,
        })

        return await resParser(response)
    }

    async createCode(
        identifier,
        reason,
        reCaptchaToken
    ) {
        const body = JSON.stringify({
            identifier: identifier,
            reason: reason,
            reCaptchaToken: reCaptchaToken
        })

        const path = process.env.URL + "/api/v1/users/code"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body,
        })

        return await resParser(response)
    }

    async updateCode(
        identifier,
        code,
        reason,
        reCaptchaToken
    ) {
        const body = JSON.stringify({
            identifier: identifier,
            code: code,
            reason: reason,
            reCaptchaToken: reCaptchaToken
        })

        const path = process.env.URL +  "/api/v1/users/code"

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON },
            body,
        })

        return await resParser(response)
    }

    async updatePassword(
        identifier,
        password,
        code,
        reCaptchaToken
    ) {
        const body = JSON.stringify({
            identifier: identifier,
            password: password,
            code: code,
            reCaptchaToken: reCaptchaToken
        })

        const path = process.env.URL + "/api/v1/users/password"

        const response = await fetch(path, {
            method: PUT,
            headers: { "Content-Type": APPLICATION_JSON },
            body,
        })

        return await resParser(response)
    }

    async updateLock(
        userId,
        isLock
    ){
        const auth = "Bearer " + store.data.token.access.value

        const body = JSON.stringify({ isLock: isLock })

        const path = process.env.URL + "/api/v1/users/" + userId + "/account-lock"

        const response = await fetch(path, {
            method: PUT,
            headers: {
                "Content-Type": APPLICATION_JSON,
                "Authorization": auth
            },
            body,
        })

        return await resParser(response)
    }
}

export const userService = new UserService();