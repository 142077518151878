import creator from "../../public/creator.jpeg"

import i18n from 'i18next'
import 'dotenv/config'
import React from "react"

export class Contact extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return <div className="col-lg-9 col-md-10 col-sm-12 mx-auto p-0">

            <div className="row shadow bg-body-tertiary rounded px-3 py-2 mx-0 mb-3">
                <div className="col-7 col-md-9 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("contact.title") }</p>
                </div>
            </div>

            <div className="container">
                <div className="h4 pb-2 border-bottom border-2 border-theme-color mb-4 shadow"></div>
            </div>

            <div className="row row-gap-3 fw-medium">
                <div className="col-12">
                    <div className="row bg-body-tertiary shadow rounded p-4 mx-0 mb-4">
                        <div className="col-12 col-md-3">
                            <img
                                src={ creator }
                                className="img-thumbnail rounded"
                                alt="..."
                                style={ { "width": "fit-content" } }
                            />
                        </div>
                        <div className="col-12 col-md-9">
                            <div className="row">
                                <div className="col-6">
                                    <div>Carthy MJ</div>
                                </div>
                                <div className="col-6 text-end">
                                    <div>user: ~ROOT</div>
                                </div>
                            </div>
                            <div className={ "h4 pb-2 border-bottom border-theme-color" }></div>
                            <div className="row">
                                <div className="col-12">
                                    <div>{ process.env.ADMIN_MAIL_CONTACT }</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}