import React, { useContext } from "react"
import { gameService } from "../service/game-service"
import { recordService } from "../service/record-service"
import { removeObjectWithId } from "../const/common"
import i18n from "i18next"
import { useLocation, useNavigate } from "react-router-dom"
import GameEditionCard from "../component/game-edition-card"
import { disconnect, isNotConnected, record } from "../const/utils"
import Login from "./login"
import { Context } from "../const/context"


export default function(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(Context)

    if (isNotConnected(context)) return <Login/>

    return <GamesToValidate { ...props } navigate={ navigate } location={ location } context={ context }/>
}

class GamesToValidate extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            modalUrl: null,
            games: [],
            records: []
        }

        this.navigate = this.props.navigate

        this.onHandleModalUrl = this.onHandleModalUrl.bind(this)
        this.onHandleStatusChange = this.onHandleStatusChange.bind(this)
        this.onHandleDelete = this.onHandleDelete.bind(this)
    }

    componentDidMount() {
        gameService.getAll("", "CREATED,UPDATED,DISABLE").then(it => {

            if (it.status === 401) return disconnect(this.navigate, this.props.context, this.props.location.pathname)
            if (it.status === 403) return this.navigate("/home")

            this.setState(
                (state, _) => ({ games: it }),
                async () => {
                    const gameIds = this.state.games.map(it => it.id)

                    await recordService.search(gameIds, []).then(it => {
                        this.setState((state, _) => ({ records: it }))
                    })
                }
            )
        })
    }

    games() {
        return this.state.games.filter(
            it => it.status !== "ENABLE"
        ).sort(
            (first, second) => new Date(first.lastModifiedDate) - new Date(second.lastModifiedDate)
        ).map(it => <React.Fragment key={ it.id }>
            <GameEditionCard
                game={ it }
                record={ record(this.state.records, it.id) }
                onHandleModalUrl={ this.onHandleModalUrl }
                onHandleStatusChange={ this.onHandleStatusChange }
                onHandleDelete={ this.onHandleDelete }
            />
        </React.Fragment>)
    }

    onHandleModalUrl(url) {
        this.setState((state, _) => ({ modalUrl: url }))
    }

    async onHandleStatusChange(game, status) {
        const res = await gameService.updateStatus(game.id, status)

        if (res.status === 401) return disconnect(this.navigate, this.props.context, this.props.location.pathname)
        if (res.status === 403) return this.navigate("/home")

        if (res.error != null || res.httpCode != null) return

        const games = this.state.games

        const index = games.findIndex(it => it.id === res.id)

        games[index] = res

        this.setState((state, _) => ({ games: games }))
    }

    async onHandleDelete(id) {
        const { status } = await gameService.delete(id)

        if (status === 401) return disconnect(this.navigate, this.props.context, this.props.location.pathname)
        if (status === 403) return this.navigate("/home")

        if (status === 204) this.setState((state, _) => ({ games: removeObjectWithId(this.state.games, id) }))
    }

    render() {
        return <div className="col-lg-9 col-md-10 col-sm-12 mx-auto">

            <div className="row shadow bg-body-tertiary rounded px-3 py-2 mx-0 mb-3">
                <div className="col-7 col-md-9 px-0 my-auto">
                    <p className="fs-2 m-0">{ i18n.t("gameToValidate.title") }</p>
                </div>
                <div className="col-5 col-md-3 text-end m-auto px-0">
                    <div className="d-flex justify-content-end">
                        <div className="fancy">
                            <button
                                className="btn btn-primary"
                                onClick={ () => { this.navigate("/game-edition") } }
                            >
                                <div className="row">
                                    <div className="col-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="17" height="17" fill="currentColor"
                                            className="bi bi-plus-circle"
                                            viewBox="0 0 20 15"
                                        >
                                            <path
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                            <path
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                        </svg>
                                    </div>
                                    <div className="col px-1">{ i18n.t("gameCardEdition.newEvent") }</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="h4 pb-2 border-bottom border-2 border-theme-color mb-4 shadow"></div>
            </div>

            { this.games() }

            <div
                className="modal fade"
                id="gameModal"
                tabIndex="-1"
                aria-labelledby="game-modal"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="col">
                            <div>
                                <img
                                    src={ this.state.modalUrl }
                                    className="card-img-top"
                                    alt="tounament_image"
                                    style={ { borderTopRightRadius: "0.375rem", borderTopLeftRadius: "0.375rem" } }
                                />
                            </div>
                            <div style={ { position: "absolute", top: "8px", right: "8px" } }>
                                <button
                                    type="button"
                                    className="z-1 btn btn-lg btn-danger shadow"
                                    data-bs-dismiss="modal"
                                    style={ { paddingBottom: "9px", paddingTop: "5px" } }
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16" height="16"
                                        fill="currentColor"
                                        className="bi bi-x m-auto"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}