import i18n from 'i18next'
import React from "react"
import { LEVEL } from "../const/common"

export class Level extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            level: {
                min: {
                    name: props.min.name,
                    position: props.min.position
                },
                max: {
                    name: props.max.name,
                    position: props.max.position
                }
            },
        }

        this.padding = props.padding !== undefined ? props.padding : "p-4"

        this.levelName = this.levelName.bind(this)
        this.levelPosition = this.levelPosition.bind(this)

        this.levelFromEnum = this.levelFromEnum.bind(this)

        this.onHandleMinRangeLevel = this.onHandleMinRangeLevel.bind(this)
        this.onHandleMaxRangeLevel = this.onHandleMaxRangeLevel.bind(this)
    }

    levelName(value) {
        switch (parseInt(value)) {
            case LEVEL.OUT_OF_COMPETITION.position: return LEVEL.OUT_OF_COMPETITION.name
            case LEVEL.LOW.position: return LEVEL.LOW.name
            case LEVEL.MIDDLE.position: return LEVEL.MIDDLE.name
            case LEVEL.HIGHER.position: return LEVEL.HIGHER.name
            case LEVEL.ELITE.position: return LEVEL.ELITE.name
            case LEVEL.PRO.position: return LEVEL.PRO.name
            case LEVEL.LEGEND.position: return LEVEL.LEGEND.name
        }
    }

    levelPosition(value) {
        switch (value) {
            case LEVEL.OUT_OF_COMPETITION.name: return LEVEL.OUT_OF_COMPETITION.position
            case LEVEL.LOW.name: return LEVEL.LOW.position
            case LEVEL.MIDDLE.name: return LEVEL.MIDDLE.position
            case LEVEL.HIGHER.name: return LEVEL.HIGHER.position
            case LEVEL.ELITE.name: return LEVEL.ELITE.position
            case LEVEL.PRO.name: return LEVEL.PRO.position
            case LEVEL.LEGEND.name: return LEVEL.LEGEND.position
        }
    }

    levelFromEnum(value) {
        return LEVEL[value].value
    }

    onHandleMinRangeLevel(e) {
        const minName = this.levelName(e.target.value)
        const minPosition = this.levelPosition(minName)

        const currentMaxPosition = this.state.level.max.position

        const maxPosition = minPosition > currentMaxPosition ? minPosition : currentMaxPosition
        const maxName = this.levelName(maxPosition)

        this.setState((state, _) => ( {
            level: {
                min: { name: minName, position: minPosition },
                max: { name: maxName, position: maxPosition }
            }
        } ),
            () => this.props.onHandleLevel(LEVEL[this.state.level.min.name], LEVEL[this.state.level.max.name])
        )
    }

    onHandleMaxRangeLevel(e) {
        const maxName = this.levelName(e.target.value)
        const maxPosition = this.levelPosition(maxName)

        const currentMinPosition = this.state.level.min.position

        const minPosition = maxPosition < currentMinPosition ? maxPosition : currentMinPosition
        const minName = this.levelName(minPosition)

        this.setState((state, _) => ( {
            level: {
                min: { name: minName, position: minPosition },
                max: { name: maxName, position: maxPosition }
            }
        } ),
            () => this.props.onHandleLevel(LEVEL[this.state.level.min.name], LEVEL[this.state.level.max.name])
        )
    }

    render() {
        return <div className={ "border rounded shadow " + this.padding }>
            <div className="text-center">
                <p>{ i18n.t("level.name") }</p>
            </div>
            <div className={ "row " + this.padding } style={ { maxHeight: "50px" } }>
                <div className="col-6">
                    <p className="text-center">
                        { i18n.t("level." + this.levelFromEnum(this.state.level.min.name)) }
                    </p>
                </div>
                <div className="col-6">
                    <div>
                        <p className="text-center">
                            { i18n.t("level." + this.levelFromEnum(this.state.level.max.name)) }
                        </p>
                    </div>
                </div>
            </div>
            <div className={ "row " + this.padding }>
                <div className="col-6">
                    <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="6"
                        step="1"
                        id="minRangeLevel"
                        value={ this.state.level.min.position }
                        onChange={ this.onHandleMinRangeLevel }
                    />
                </div>
                <div className="col-6">
                    <input
                        type="range"
                        className="form-range"
                        min="0"
                        max="6"
                        step="1"
                        id="maxRangeLevel"
                        value={ this.state.level.max.position }
                        onChange={ this.onHandleMaxRangeLevel }
                    />
                </div>
            </div>
        </div>
    }
}