{
  "navBar": {
    "home": "Home",
    "submitTournament": "Submit tournament",
    "my-tournaments": "My tournaments",
    "all-tournaments": "All Tournaments",
    "gameToValidate": "Game to validate",
    "contact": "Contact",
    "games": "Games",
    "users": "Users",
    "login": "Login",
    "account": {
      "name": "Account",
      "setting": "Setting",
      "logout": "Logout"
    }
  },
  "sideBar": {
    "title": "Filter",
    "keyword": "Keyword",
    "region": {
      "name": "Region",
      "choose": "Choose"
    },
    "date": {
      "startDate": "Start date",
      "endDate": "End date"
    },
    "search": "Search"
  },
  "record": {
    "dragNDrop": "Drag 'n' drop some files here, or click to select a picture",
    "limit": "Limit",
    "picture-too-heavy": "Picture too heavy"
  },
  "common": {
    "yes": "Yes",
    "no": "No",
    "to": "to",
    "of": {
      "default": "of",
      "d": ""
    },
    "and": "and",
    "new": "new",
    "or": "or",
    "more": "More",
    "choose": "Choose",
    "hasBeen": "has been",
    "open": "Open",
    "close": "Close",
    "copy": "Copy",
    "identifier": "Identifier",
    "confirm": "Confirm",
    "address": "Address",
    "phoneNumber": "Phone number",
    "mail": "Email",
    "details": "Details",
    "country": "Country",
    "username": "Username",
    "password": {
      "name": "Password",
      "rule": {
        "1SpecialCharacter": "One special character",
        "1UppercaseCharacter": "One uppercase character",
        "1LowercaseCharacter": "One lowercase character",
        "moreThan8Characters": "More than 8 characters"
      }
    },
    "firstname": "Firstname",
    "lastname": "Lastname",
    "male": "Male",
    "female": "Female",
    "other": "Other"
  },
  "region": {
    "ANTWERP": "Antwerp",
    "EAST_FLANDERS": "East Flanders",
    "FLEMISH_BRABANT": "Flemish Brabant",
    "LIMBURG": "Limburg",
    "WEST_FLANDERS": "West Flanders",
    "HAINAUT": "Hainaut",
    "LIEGE": "Liège",
    "LUXEMBOURG": "Luxembourg",
    "NAMUR": "Namur",
    "WALLOON_BRABANT": "Walloon Brabant",
    "BRUSSELS_CAPITAL_REGION": "Brussels Capital Region"
  },
  "button": {
    "enable": "Enable",
    "activate": "Enable",
    "activated": "Activated",
    "disable": "Disable",
    "disabled": "Disabled",
    "reOpen": "Re-open",
    "waitingForValidation": "Waiting for validation",
    "add": "Add",
    "update": "Update",
    "cancel": "Cancel",
    "delete": "Delete",
    "back": "Back",
    "save": "Save",
    "send": "Send"
  },
  "toast": {
    "error": {
      "not-found": "Not found",
      "user-not-found": "User is not found",
      "session-not-found": "Session is not found",
      "game-not-found": "Game is not found",
      "auth-not-found": "Accreditation is not found",
      "media-not-found": "Publication is not found",

      "mail-not-valid": "Mail not valid",
      "validation-fail": "Validation fail",
      "access-denied": "Access denied",
      "user-not-yet-verified": "User not yet verified",
      "process-issue": "Error on the process",
      "root-undeactivable": "Invalid root user can not be disabled",
      "account-verified": "Account already verified",
      "user-disabled": "User is disabled",
      "retry-another-code": "Retry with another code or regenerate one",
      "user-duplication": "User already existing",
      "auth-denied": "Authorization denied",
      "invalid-file-size": "Invalid file size",
      "username-duplication": "Username already taken",

      "server-error": "An unexpected server error has occurred"
    },
    "success": {
      "user-created": "User successfully created",
      "connected": "Connected",
      "new-code-generated": "New code generated in your mail box",
      "password-update": "Password updated"
    },
    "info": {
      "event-waiting-validate": "Event waiting validation"
    }
  },
  "level": {
    "name": "Level",
    "leisure": "Leisure",
    "dep": "Departmental",
    "reg": "Regional",
    "nat": "National",
    "elite": "Elite",
    "pro": "Pro",
    "legend": "Legend"
  },
  "success": {
    "title": "Congratulations",
    "default-message": "Your action has been validated"
  },
  "error": {
    "message": "Sorry, an unexpected error has occurred."
  },
  "settings": {
    "name": "Settings",
    "theme": {
      "auto": "Auto",
      "light": "Light",
      "dark": "Dark"
    },
    "language": {
      "name": "Language",
      "french": "French",
      "english": "English"
    }
  },
  "home": {
    "noGameFound": "No games found",
    "recently-added": "Recently added",
    "weekOf": "Week of",
    "players": "Players",
    "gender": {
      "onlyMale": "Only male",
      "onlyFemale": "Only female",
      "unknown": "Multiple choice",
      "mixed": "Mixed",
      "male": "male",
      "female": "female",
      "min": "minimum"
    },
    "deleteFilter": "Delete filters",
    "sureToDeleteGame": "Are you sure to delete"
  },
  "login": {
    "title": "Please sign in",
    "rememberMe": "Remember me",
    "signIn": "Sign in",
    "continue-as-guest": "Continue as guest",
    "createAccount": "create an account",
    "passwordForgotten": "password forgotten",
    "validAccount": "valid your account"
  },
  "registry": {
    "title": "Please registry",
    "mailAddress": "Email address",
    "passwordConfirmation": "Password Confirmation",
    "bornDate": "Born date",
    "bornDateInput": "Born date - ex: 1970-02-30",
    "registry": "Registry",
    "rule": {
      "bornDate": {
        "userShouldBeOlderThan18": "User should be older than 18 years"
      }
    }
  },
  "identifier": {
    "title": "Select your identifier",
    "next": "Next"
  },
  "password": {
    "title": "Reset your password",
    "newPassword": "New password",
    "code": "code",
    "reset": "Reset",
    "newCode": "New code"
  },
  "code": {
    "title": "Verification",
    "code": "Code",
    "send": "Send",
    "newCode": "New code"
  },
  "gameCardEdition": {
    "title": "My tournaments",
    "newEvent": "New"
  },
  "games-all": {
    "title": "All tournaments"
  },
  "gameEdition": {
    "title": "Organize your event",
    "name": "Name",
    "address": "Address",
    "region": "Region",
    "price": "Price",
    "phoneNumber": "Phone number",
    "mail": "mail",
    "file": {
      "name": "Choose file",
      "noFile": "No image chosen"
    },
    "addImage": "Add a picture",
    "deleteImage": "Delete image",
    "nbOfTeam": "Number of team",
    "nbOfGround": "Number of ground",
    "nbOfPlayerByTeam": "Number of player by team",
    "minOfMale": "Min of male",
    "minOfFemale": "Min of female",
    "detail": "Detail",
    "default-message": "Default message",
    "email-sending-code": "Email sending code",
    "code-send-by-mail": "Code sent by email",
    "congrats":"Your tournament has been created, it is awaiting validation"
  },
  "managers": {
    "title": "Managers"
  },
  "gameToValidate": {
    "title": "Games awaiting validation"
  },
  "user-settings": {
    "title": "Account",
    "past-connexion": "Connexion",
    "connexion-card": {
      "date-of-connexion": "Date of connexion",
      "browser": "Browser",
      "device": "Device",
      "authentication-successful": "Authentication successful"
    },
    "authorization": "Authorization"
  },
  "sessions": {
    "title": "Connection"
  },
  "users":{
    "title": "Users",
    "expired": "Expired",
    "blocked": "Blocked"
  },
  "social-network": {
    "title": "Media",
    "default": "Default publication",
    "reminder": "Reminder",
    "full": "Full",
    "capacity": "Capacity limited",
    "network": "Network",
    "too-many-publication": "To make more publications contact the administrator of acrootournois.fr"
  },
  "contact": {
    "title": "Contact",
    "submit": "Submit an email",
    "emailAddress": "Email address"
  }
}