import React from "react"
import {
    actionButton,
    capitalizeFirstLetter,
    detail,
    gender,
    level, modalGameDelete,
    regionFinder,
    regionTranslation
} from "../const/common"
import i18n from "i18next"
import { startDate } from "../const/utils"
import { Context } from "../const/context"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import ShowcasePicture from "./showcase-picture"

export default function(props) {
    const navigate = useNavigate()
    const context = useContext(Context)

    return <GameEditionCard { ...props } navigate={ navigate } context={ context } />
}

class GameEditionCard extends React.Component {

    constructor(props) {
        super(props)

        this.navigate = props.navigate

        this.onHandleModalUrl = props.onHandleModalUrl
        this.onHandleStatusChange = props.onHandleStatusChange
        this.onHandleDelete = props.onHandleDelete

    }

    onHandleUpdate = (game, record) => this.navigate("/game-edition", { state: { game: game, file: record } })

    onHandleManager = (game) => this.navigate("/managers", { state: { game: game } })

    onHandleSocialNetwork = (game) => this.navigate("/social-network", { state: { game: game } })

    footer(it) {
        const status = it.status
        const isNotEnable = status !== "DISABLE" && status !== "ENABLE"

        const leftButton = isNotEnable ? <button
            type="button"
            className="btn btn-warning btn-sm col-12"
            onClick={ () => this.onHandleStatusChange(it, "DISABLE", false) }
            disabled={ status === "CREATED" }
        >
            { i18n.t("button.disable") }
        </button> : ""

        const rightButton = actionButton(it, this.props.context, this.onHandleStatusChange, "btn-secondary")

        return <div className="ps-md-2 pe-md-0 px-0">
            <div className="row m-auto px-0">
                <div className="col-6 ps-0 pe-1">{ leftButton }</div>
                <div className={ isNotEnable ? "col-6 ps-1 pe-0" : "col-12 p-0" }>{ rightButton }</div>
            </div>
        </div>
    }

    status = (status) => {
        switch (status) {
            case "CREATED": return <button
                type="button"
                className="btn btn-outline-info btn-sm active col-12"
            >
                { i18n.t("button.waitingForValidation") }
            </button>
            case "UPDATED": return <button
                type="button"
                className="btn btn-outline-info btn-sm active col-12"
            >
                { i18n.t("button.waitingForValidation") }
            </button>
            case "ENABLE": return <button
                type="button"
                className="btn btn-outline-success btn-sm active col-12"
            >
                { i18n.t("button.activate") }
            </button>
            case "DISABLE":return <button
                type="button"
                className="btn btn-outline-warning btn-sm active col-12"
            >
                { i18n.t("button.disabled") }
            </button>
        }
    }

    statusDiv(game) {
        const status = game.status === "ENABLE" ? this.enableStatus(game) : <div className="ps-0">{ this.status(game.status) }</div>

        return <div className="d-flex justify-content-between px-0">
            <div className="row col-10 px-0 mx-0">
                { status }
            </div>
            <div className="col-2">
                <button
                    className="btn btn-danger btn-sm col-12 h-100"
                    data-bs-toggle="modal"
                    data-bs-target={ "#modalGameDelete" + game.id }
                    data-bs-whatever="@mdo"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash3"
                        viewBox="0 0 16 16"
                    >
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                    </svg>
                </button>
            </div>
        </div>
    }

    enableStatus(game) {
        return <React.Fragment>
            <div className="col-7 px-0">
                { this.status(game.status) }
            </div>
            <div className="col-5">
                <button
                    className="btn btn-bd-custom btn-sm px-0 col-12"
                    onClick={ () => this.onHandleSocialNetwork(game) }
                >
                    <div className="d-flex justify-content-evenly"
                         style={ { paddingTop: "2.5px", paddingBottom: "2.5px" } }>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16"
                            fill="currentColor"
                            className="bi bi-facebook"
                            viewBox="0 0 16 16"
                        >
                            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16" height="16"
                            fill="currentColor"
                            className="bi bi-instagram ms-2"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                        </svg>
                    </div>
                </button>
            </div>
        </React.Fragment>
    }

    render() {
        const it = this.props.game
        const record = this.props.record
        const region = regionFinder(it.region)

        return <div className="modal-content rounded-2 shadow fw-medium bg-body-tertiary p-4 my-4">
            <div className="row row-gap-3">
                <div className="col-12 col-md-4">
                    <div className="row row-gap-3 m-auto">
                        { this.statusDiv(it) }
                        <div className="p-0 rounded">
                            <ShowcasePicture url={ record } onHandleModalUrl={ this.onHandleModalUrl }/>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-8 px-0">
                    <div className="row row-gap-3 px-0 mx-0">
                        <div className="row row-gap-3 col-12 ms-0">
                            <div className="row col-12 col-md-6 mx-0 px-0">
                                <div className="col-6 ps-0 pe-1">
                                    <button
                                        className="btn btn-secondary btn-sm px-0 col-12"
                                        onClick={ () => this.onHandleUpdate(it, record) }
                                    >
                                        { i18n.t("button.update") }
                                    </button>
                                </div>
                                <div className="col-6 ps-1 pe-0">
                                <button
                                        className="btn btn-secondary btn-sm px-1 col-12"
                                        onClick={ () => this.onHandleManager(it) }
                                    >
                                        { i18n.t("managers.title") }
                                    </button>
                                </div>
                            </div>
                            <div className="row col-12 col-md-6 mx-0 px-0">
                                { this.footer(it) }
                            </div>
                        </div>
                        <div className="row row-gap-1 px-0 mx-0">
                            <div className="col">
                                <div className="d-flex justify-content-between">
                                    <div className="col-9 col-md-10">
                                        { it.name }
                                    </div>
                                    <div className="col-3 col-md-2 text-end">
                                        { it.price } Є
                                    </div>
                                </div>
                                <div className="h4 pb-2 border-bottom border-2 border-theme-color"></div>
                            </div>
                            <div className="row row-gap-1 m-auto px-0 small">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        { capitalizeFirstLetter(it.ground.toLowerCase()) }
                                    </div>
                                    <div>
                                        { startDate(it.startDate) }
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        { it.nbOfPlayer } { i18n.t("home.players") }
                                    </div>
                                    <div>
                                        { gender(it) }
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        { i18n.t("level.name") }
                                    </div>
                                    <div>
                                        { level(it) }
                                    </div>
                                </div>
                                <div className="row row-gap-1">
                                    <div>
                                        { it.address }
                                    </div>
                                    <div>
                                        { regionTranslation(region) }, { capitalizeFirstLetter(region.country) }
                                    </div>
                                </div>
                                <div
                                    className={ "row " + (it.phoneNumber != null && it.mail != null ? "row-gap-1" : "") }>
                                    <div>
                                        { it.phoneNumber }
                                    </div>
                                    <div>
                                        { it.mail }
                                    </div>
                                </div>
                                <div>
                                    { detail(it) }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            { modalGameDelete(it, this.onHandleDelete) }
        </div>
    }
}