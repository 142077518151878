import { store } from "../repository/store"
import { GET } from "../const/request"
import { resParser } from "../const/utils"

class NotifierService {

    async notifier(
        emittedTime
    ) {

        const auth = store.data.token.access.value !== null ? "Bearer " + store.data.token.access.value : null

        const headers = auth === null ? { } : { "Authorization": auth }

        const timeZone = window.locale.timeZone

        const path = process.env.URL + "/api/v1/notifiers?emittedTime=" + emittedTime + "&zone=" + timeZone

        const response = await fetch(path, {
            method: GET,
            headers: headers
        })

        return await resParser(response)
    }
}

export const notifierService = new NotifierService()