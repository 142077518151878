import './css/theme.scss'
import './config/i18n'

import React from "react"
import ReactDOM from "react-dom/client"
import {
    Route,
    createRoutesFromElements,
    RouterProvider,
    createBrowserRouter
} from "react-router-dom";
import { Error } from "./route/error"
import Home from "./route/home"
import Login from "./route/login"
import Registry from "./route/registry"
import Code from "./route/code"
import Identifier from "./route/identifier"
import Password from "./route/password"
import Games from "./route/games"
import GamesAll from "./route/games-all"
import GameEdition from "./route/game-edition"
import dotenv from "dotenv"
import { Contact } from "./route/contact"
import { handleMobileCheck, handleMachineTheme } from "./const/utils"
// import ThemeButton from "./navbar/theme-button"
import Settings from "./route/settings"
import GamesToValidate from "./route/games-to-validate"
import Users from "./route/users"
import Managers from "./route/managers"
import Success from "./route/success"
import { PrivacyPolicy } from "./route/privacy-policy"
import SocialNetwork from "./route/social-network"

/* ROOT LAYOUT */
import RootLayout from "./config/root-layout"
import UserSettings from "./route/user-settings"
import Sessions from "./route/sessions"

window.recaptchaOptions = { useRecaptchaNet: true }
window.mobileCheck = handleMobileCheck()
window.theme = handleMachineTheme()
window.locale = Intl.DateTimeFormat().resolvedOptions()

const error = console.error

console.error = (...args) => {
    if (/defaultProps/.test(args[0])) return;
    error(...args);
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={ <RootLayout /> }>
            <Route index element={ <Home/> } />

            <Route path="/home" element={ <Home /> } />

            <Route path="/users" element={ <Users /> } />
            <Route path="/user-settings" element={ <UserSettings/> } />
            <Route path="/my-settings" element={ <UserSettings/> } />
            <Route path="/sessions" element={ <Sessions/> } />

            <Route path="/contact" element={ <Contact /> } />

            <Route path="/login" element={ <Login /> } />
            <Route path="/registry" element={ <Registry /> } />
            <Route path="/password" element={ <Password /> } />
            <Route path="/identifier" element={ <Identifier /> } />
            <Route path="/code" element={ <Code /> } />

            <Route path="/games" element={ <Games /> } />
            <Route path="/games-all" element={ <GamesAll /> } />
            <Route path="/game-edition" element={ <GameEdition /> } />
            <Route path="/games-to-validate" element={ <GamesToValidate /> } />
            <Route path="/managers" element={ <Managers /> } />
            <Route path="/social-network" element={ <SocialNetwork /> } />

            <Route path="/settings" element={ <Settings /> } />
            <Route path="/success" element={ <Success /> } />

            <Route path="/privacy-policy" element={ <PrivacyPolicy /> } />

            <Route path="/*" element={ <Error /> } />
        </Route>
    )
)

dotenv.config() /* CONFIG ENV VARIABLES */

const App = () => { return (<RouterProvider router={ router } />) }

ReactDOM.createRoot(document.querySelector("#app")).render(<App />)