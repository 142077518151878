import "dotenv/config"

import { store } from "../repository/store"
import { APPLICATION_JSON, DELETE, POST } from "../const/request"
import { resParser } from "../const/utils"

class RecordService {

    async createAsGuest(
        file,
        documentName,
        documentId,
        authorizationId,
        authorizationCode
    ) {
        const formData = new FormData()

        formData.append("file", file, file.name)

        const path = process.env.URL + "/api/v1/documents/" + documentName + "-" + documentId + "/records/as-guest"

        const headers = {
            "Authorization-Id": authorizationId,
            "Authorization-Code": authorizationCode
        }

        const response = await fetch(path, {
            method: POST,
            headers: headers,
            body: formData,
        })

        return await resParser(response)
    }

    async create(
        file,
        documentName,
        documentId,
    ) {
        const auth = "Bearer " + store.data.token.access.value

        const formData = new FormData()

        formData.append("file", file, file.name)

        const path = process.env.URL + "/api/v1/documents/" + documentName + "-" + documentId + "/records"

        const response = await fetch(path, {
            method: POST,
            headers: { "Authorization": auth },
            body: formData,
        })

        return await resParser(response)
    }

    async search(
        mapperIds,
        types
    ) {
        const body = JSON.stringify({
            mapperIds: mapperIds,
            types: types,
            page: 0,
            size: 100
        })

        const path = process.env.URL + "/api/v1/records/search"

        const response = await fetch(path, {
            method: POST,
            headers: { "Content-Type": APPLICATION_JSON },
            body: body,
        })

        return await resParser(response)
    }

    async delete(
        documentName,
        documentId,
    ) {
        const auth = "Bearer " + store.data.token.access.value

        const path = process.env.URL + "/api/v1/documents/" + documentName + "-" + documentId + "/records"

        const response = await fetch(path, {
            method: DELETE,
            headers: { "Authorization": auth, "Content-Type": APPLICATION_JSON },
        })

        return await resParser(response)
    }
}

export const recordService = new RecordService()