import indoor from "../../public/indoor.png"
import parc from "../../public/parc.png"
import beachVolley from "../../public/beach-volley.png"
import sea from "../../public/sea.png"
import mountain from "../../public/mountain.png"
import france from "../../public/france.png"
import belgium from "../../public/belgium.png"

import i18n from "i18next"
import React from "react"
import { handleTheme } from "./utils"
import Linkify from "react-linkify"
import man from "../../public/man.png"
import woman from "../../public/woman.png"
import transgender from "../../public/transgender.png"

export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i
export const PHONE_REGEX = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/i

export const DATE_FORMAT = "YYYY-MM-DD"

export const NESTED_NAV_LINK_STYLE = {
    paddingRight: "var(--bs-navbar-nav-link-padding-x)",
    paddingLeft: "var(--bs-navbar-nav-link-padding-x)"
}

export const INVERSE_COLOR = {
    primary: "warning",
    warning: "primary",
    success: "warning",
    danger: "primary"
}

export const GROUND = [
    {
        name: "INDOOR",
        key: "INDOOR-VOLLEY",
        value: "Indoor",
        image: indoor
    },
    {
        name: "GREEN",
        key: "GREEN-VOLLEY",
        value: "Green",
        image: parc
    },
    {
        name: "BEACH",
        key: "BEACH-VOLLEY",
        value: "Beach",
        image: beachVolley
    },
    {
        name: "WATER",
        key: "WATER-VOLLEY",
        value: "Water",
        image: sea
    },
    {
        name: "SNOW",
        key: "SNOW-VOLLEY",
        value: "Snow",
        image: mountain
    }
]

export const LEVEL = {
    OUT_OF_COMPETITION: {
        name: "OUT_OF_COMPETITION",
        key: "OUT_OF_COMPETITION",
        value: "leisure",
        position: 0
    },
    LOW: {
        name: "LOW",
        key: "LOW",
        value: "dep",
        position: 1
    },
    MIDDLE: {
        name: "MIDDLE",
        key: "MIDDLE",
        value: "reg",
        position: 2
    },
    HIGHER: {
        name: "HIGHER",
        key: "HIGHER",
        value: "nat",
        position: 3
    },
    ELITE: {
        name: "ELITE",
        key: "ELITE",
        value: "elite",
        position: 4
    },
    PRO: {
        name: "PRO",
        key: "PRO",
        value: "pro",
        position: 5
    },
    LEGEND: {
        name: "LEGEND",
        key: "LEGEND",
        value: "legend",
        position: 6
    },
}

export const FLAG_BY_COUNTRY = {
    "FRANCE": france,
    "BELGIUM": belgium
}

export const COUNTRY_PAIRS = {
    "FRANCE": "FR",
    "BELGIUM": "BE"
}

export const COUNTRY = [
    {
        key: "FRANCE",
        value: "France",
    },
    {
        key: "BELGIUM",
        value: "Belgique",
    },
]

export const REGION = [
    {
        key: "AUVERGNE_RHONE_ALPES",
        value: "Auvergne-Rhône-Alpes",
        country: "FRANCE"
    },
    {
        key: "BOURGOGNE_FRANCHE_COMTE",
        value: "Bourgogne-Franche-Comté",
        country: "FRANCE"
    },
    {
        key: "BRETAGNE",
        value: "Bretagne",
        country: "FRANCE"
    },
    {
        key: "CENTRE_VAL_DE_LOIRE",
        value: "Centre-Val de Loire",
        country: "FRANCE"
    },
    {
        key: "CORSE",
        value: "Corse",
        country: "FRANCE"
    },
    {
        key: "GRAND_EST",
        value: "Grand Est",
        country: "FRANCE"
    },
    {
        key: "HAUTS_DE_FRANCE",
        value: "Hauts-de-France",
        country: "FRANCE"
    },
    {
        key: "ILE_DE_FRANCE",
        value: "Ile-de-France",
        country: "FRANCE"
    },
    {
        key: "NORMANDIE",
        value: "Normandie",
        country: "FRANCE"
    },
    {
        key: "NOUVELLE_AQUITAINE",
        value: "Nouvelle-Aquitaine",
        country: "FRANCE"
    },
    {
        key: "OCCITANIE",
        value: "Occitanie",
        country: "FRANCE"
    },
    {
        key: "PAYS_DE_LA_LOIRE",
        value: "Pays de la Loire",
        country: "FRANCE"
    },
    {
        key: "PROVENCE_ALPES_COTE_D_AZUR",
        value: "Provence Alpes Côte d’Azur",
        country: "FRANCE"
    },
    {
        key: "GUADELOUPE",
        value: "Guadeloupe",
        country: "FRANCE"
    },
    {
        key: "GUYANE",
        value: "Guyane",
        country: "FRANCE"
    },
    {
        key: "MARTINIQUE",
        value: "Martinique",
        country: "FRANCE"
    },
    {
        key: "MAYOTTE",
        value: "Mayotte",
        country: "FRANCE"
    },
    {
        key: "REUNION",
        value: "Réunion",
        country: "FRANCE"
    },
    {
        key: "ANTWERP",
        value: "Antwerp",
        country: "BELGIUM"
    },
    {
        key: "EAST_FLANDERS",
        value: "East Flanders",
        country: "BELGIUM"
    },
    {
        key: "FLEMISH_BRABANT",
        value: "Flemish Brabant",
        country: "BELGIUM"
    },
    {
        key: "LIMBURG",
        value: "Limburg",
        country: "BELGIUM"
    },
    {
        key: "WEST_FLANDERS",
        value: "West Flanders",
        country: "BELGIUM"
    },
    {
        key: "HAINAUT",
        value: "Hainaut",
        country: "BELGIUM"
    },
    {
        key: "LIEGE",
        value: "Liège",
        country: "BELGIUM"
    },
    {
        key: "LUXEMBOURG",
        value: "Luxembourg",
        country: "BELGIUM"
    },
    {
        key: "NAMUR",
        value: "Namur",
        country: "BELGIUM"
    },
    {
        key: "WALLOON_BRABANT",
        value: "Walloon Brabant",
        country: "BELGIUM"
    },
    {
        key: "BRUSSELS_CAPITAL_REGION",
        value: "Brussels Capital Region",
        country: "BELGIUM"
    },
]

export const SPACE = " "

export function parseJwt(token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
        window.atob(base64).split('').map(
            function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }
        ).join('')
    )
    return JSON.parse(jsonPayload)
}

export function capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}

export function monthFormat(value) {
    return capitalizeFirstLetter(value)
}

export function removeObjectWithId(arr, id) {
    const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
    arr.splice(objWithIdIndex, 1);
    return arr;
}

export function country() {
    return <React.Fragment>
        <option key="CHOOSE" value="CHOOSE" defaultValue>{ i18n.t("common.choose") }...</option>
        {
            COUNTRY.map(
                it => <option key={ it.key } value={ it.key }>{ it.value }</option>
            )
        }
    </React.Fragment>
}

export function regionByCountry(country) {
    const isCountrySelected = country !== "" && country !== "CHOOSE"

    const regions = isCountrySelected ? REGION.filter(it => it.country === country) : REGION

    return <React.Fragment>
        <option key="CHOOSE" value="CHOOSE" defaultValue>{ i18n.t("common.choose") }...</option>
        {
            regions.map(
                it => {
                    return <option key={ it.key } value={ it.key }>
                        { regionTranslation(it) } { !isCountrySelected ? "[" + COUNTRY_PAIRS[it.country] + "]" : ""}
                    </option>
                }
            )
        }
    </React.Fragment>
}

export function gender(game) {
    const nbOfPlayer = game.nbOfPlayer
    const minMaleInTeam = game.minMaleInTeam
    const minFemaleInTeam = game.minFemaleInTeam

    return genderWith(nbOfPlayer, minMaleInTeam, minFemaleInTeam)
}

export function genderWith(nbOfPlayer, minMaleInTeam, minFemaleInTeam) {
    if (minMaleInTeam === nbOfPlayer) return i18n.t("home.gender.onlyMale")
    else if (minFemaleInTeam === nbOfPlayer) return i18n.t("home.gender.onlyFemale")
    else if (minMaleInTeam === 0 && minFemaleInTeam === 0) return i18n.t("home.gender.unknown")
    else if (minMaleInTeam === 0 && minFemaleInTeam > 0) return i18n.t("home.gender.mixed") + ", " + minFemaleInTeam + " " + i18n.t("home.gender.female") + " " + i18n.t("home.gender.min")
    else if (minFemaleInTeam === 0 && minMaleInTeam > 0) return i18n.t("home.gender.mixed") + ", " + minMaleInTeam + " " + i18n.t("home.gender.male") + " " + i18n.t("home.gender.min")
    else if (minMaleInTeam > 0 && minFemaleInTeam > 0) return i18n.t("home.gender.mixed") + ", " + minMaleInTeam + " " + i18n.t("home.gender.male") + " " + i18n.t("common.and") + " " + minFemaleInTeam + " " + i18n.t("home.gender.female") + " " + i18n.t("home.gender.min")
}

export function level(game) {
    const levelFrom = game.levelFrom
    const levelTo = game.levelTo

    return levelWith(levelFrom, levelTo)
}

export function levelWith(levelFrom, levelTo) {
    if (levelFrom === levelTo) return i18n.t("level." + LEVEL[levelFrom].value)

    return i18n.t("level." + LEVEL[levelFrom].value) +  " - " + i18n.t("level." + LEVEL[levelTo].value)
}

export function regionFinder(value) {
    return REGION.filter(it => it.key === value)[0]
}

export function regions(search) {
    const region = search.region
    const country = search.country

    const isRegionSelected = region !== undefined && region !== "CHOOSE"
    const isCountrySelected = country !== undefined && country !== "CHOOSE"

    if (isRegionSelected) return [ region ]
    if (isCountrySelected) return REGION.filter(it => it.country === country).map(it => it.key)

    return []
}

export function regionTranslation(region) {
    const key = "region." + region.key

    const translation = i18n.t(key)

    if (translation !== key) return translation

    return region.value
}

export function detail(game) {
    const isEmptyDetail = game.detail == null || game.detail === ""

    if (isEmptyDetail) return <React.Fragment></React.Fragment>

    const color = handleTheme() === "dark" ? "white" : "black"

    return <React.Fragment>
        <div className="h4 pb-2 border-bottom border-2 border-theme-color"></div>
        <div className="row fw-semibold">
            <Linkify properties={ { target: '_blank', style: { color: color } } }>
                <div style={ { whiteSpace: "pre-wrap", overflowWrap: "break-word" } }>{ game.detail }</div>
            </Linkify>
        </div>
    </React.Fragment>
}

export function actionButton(
    game,
    context,
    onHandleStatusChange,
    btnColor
) {
    const status = game.status

    if (status === "ENABLE") return<button
        type="button"
        className={ "btn btn-sm btn-warning col-12 " + btnColor }
        onClick={ () => onHandleStatusChange(game, "DISABLE") }
    >
        { i18n.t("button.disable") }
    </button>
    else if (status === "CREATED" || status === "UPDATED" || status === "RE_OPEN") return <button
        type="button"
        className={ "btn btn-sm col-12 " + btnColor }
        disabled={!context.user.isAdmin}
        onClick={ () => onHandleStatusChange(game, "ENABLE") }
    >
        { i18n.t("button.enable") }
    </button>
    else if (status === "DISABLE") return <button
        type="button"
        className={ "btn btn-sm col-12 " + btnColor }
        onClick={ () => onHandleStatusChange(game, "UPDATED") }
    >
        { i18n.t("button.reOpen") }
    </button>
}

export function modalGameDelete(game, onHandleDelete) {
    return <div
        className="modal fade"
        id={ "modalGameDelete" + game.id }
        tabIndex="-1"
        aria-labelledby="modal-game-delete"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header p-4">
                    <span className="modal-title fs-5 me-4" id="deleteGameModalToggleLabel">
                        { i18n.t("home.sureToDeleteGame") } ?
                    </span>
                    <button type="button" className="btn-close ms-2" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0 m-0">
                    <button
                        className="btn btn-danger col-12 rounded-top-0"
                        data-bs-dismiss="modal"
                        onClick={ () => onHandleDelete(game.id) }
                    >{ i18n.t("button.delete") }</button>
                </div>
            </div>
        </div>
    </div>
}

export function pictureByGender(gender) {
    if (gender === "MALE") return man
    else if (gender === "FEMALE") return woman
    else return transgender
}